import { SmileOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import React from 'react'

const Home = () => {
    const name = localStorage.getItem('logged_in_user_name')
  return (
    <Result
    icon={<SmileOutlined />}
    // status="info"
    title={`Hello, ${name}!` }
  />
  )
}

export default Home
