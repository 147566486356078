import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import { pcnActionTypes, pcnContraventionTypes, pcnFaultTypes, PcnStatusTypes, pcnTypes, vehicleTypes } from "../../Components/common/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import AuthService from "../../Components/auth/AuthService";
import config from "./../../Components/config";
import moment from "moment";
import qs from "query-string";
import Message from "../../Components/common/Message";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { DatePickerField } from "../../Components/InputFields/InputFields";
import dayjs from "dayjs";
import { DatePicker as AntDate } from "antd";

const useStyles = makeStyles((theme) => ({
  dateField: {
    width: "60%",
    height: "37px",
  }
}));

const Auth = new AuthService();

const Index = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  var layoutDispatch = useLoadingDispatch();
  const [drivers, setDrivers] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [selectedRoute, setSelectedRoute] = React.useState('');
  const [selectedDriver, setSelectedDriver] = React.useState({ id: "", title: "" });
  const [selectedDriverPhone, setSelectedDriverPhone] = React.useState('');
  const [selectedDriverEmail, setSelectedDriverEmail] = React.useState('');
  const [routeOrders, setRouteOrders] = React.useState('');
  const [selectedCustomer, setSelectedCustomer] = React.useState('');
  const [closeStatus, setCloseStatus] = React.useState('');

  const [selectedPcnType, setSelectedPcnType] = React.useState({ id: "", title: "" });
  const [selectedVehicle, setSelectedVehicle] = React.useState({ id: "", title: "" });
  const [selectedFault, setSelectedFault] = React.useState({ id: "", title: "" });
  const [selectedContra, setSelectedContra] = React.useState({ id: "", title: "" });
  const [selectedStatus, setSelectedStatus] = React.useState({ id: "", title: "" });
  const [selectedAction, setSelectedAction] = React.useState({ id: "", title: "" });
  const [selectedNotes, setSelectedNotes] = React.useState('');
  const [selectedAmount, setSelectedAmount] = React.useState('');
  const [selectedPostCode, setSelectedPostCode] = React.useState('');
  const [selectedPcnDate, setSelectedPcnDate] = useState(null);
  const [selectedPcnRecDate, setSelectedPcnRecDate] = useState(null);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [pcnFile, setPcnFile] = React.useState([]);
  const [appealFile, setApealFile] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [errors, setErrors] = useState({
    type: '',
    route: '',
    driver: '',
    vehicle: '',
    fault: '',
    contra: '',
    amount: '',
    status: '',
    action: '',
    postCode: '',
    pcnDate: '',
    pcnRecDate: '',
  });
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });
  const [customerField, setCustomerField] = React.useState('');

  const getAllDriversByVehicle = async (vehicle_id) => {

    showLoading(layoutDispatch);
    const dataToSend = {
      delivery_date: moment(selectedPcnDate).format("YYYY-MM-DD"),
      vehicle_id: vehicle_id
    }

    let url = "";
    let params = {};
    if (selectedPcnType?.id == "1") {
      url = '/route/load-driver-by-vehicle';
      params.method = "POST";
      params.body = JSON.stringify(dataToSend);
    }
    else {
      url = '/driver/drivers';
    }


    if (!vehicle_id && selectedPcnType?.id == "1") return;

    try {
      const response = await Auth.fetch(`${url}`, params);
      if (response) {
        hideLoading(layoutDispatch);
        if (response.ack) {
          setDrivers(response.drivers);
          if (response.drivers.length == 1) {
            setSelectedDriver(response.drivers[0]);
            setSelectedDriverPhone(response?.drivers[0]?.phone)
            setSelectedDriverEmail(response?.drivers[0]?.email)
            getAllRoutesByVehicle(vehicle_id, response.drivers[0].id);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getAllVehicles = async () => {
    if (!Number(selectedPcnType?.id) > 0) {
      return;
    }

    showLoading(layoutDispatch);
    const dataToSend = {
      delivery_date: moment(selectedPcnDate).format("YYYY-MM-DD"),
    }

    let url = selectedPcnType?.id == "1" ? '/route/load-vehicles-by-route-date' : '/vehicle/vehicles';
    try {
      const response = await Auth.fetch(`${url}`, {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response) {
        hideLoading(layoutDispatch);
        if (response.ack) {
          setVehicles(response.vehicles);
          // if (current_vehicle) {

          //   const currentVehicle = response.vehicles.find(obj => obj.vehicle_id == current_vehicle);
          //   setSelectedVehicle(currentVehicle)
          // }
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }

  const getAllRoutesByVehicle = async (vehicle_id, driver_id) => {
    showLoading(layoutDispatch);
    const dataToSend = {
      delivery_date: moment(selectedPcnDate).format("YYYY-MM-DD"),
      vehicle_id: vehicle_id,
      driver_id: driver_id
    }
    try {
      const response = await Auth.fetch("/route/load-route-by-vehicle", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response) {
        hideLoading(layoutDispatch);
        if (response.ack) {
          setRoutes(response.routes);
          if (response.routes.length == 1) {
            setSelectedRoute(response.routes[0]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmitImage = (e, side) => {
    e.preventDefault();
    let uploadPromises = [];
    let acceptedFiles = e.target.files;

    showLoading(layoutDispatch);
    for (let file of acceptedFiles) {
      // var fileExt = file.name.split(".").pop();
      // let temp = Math.random().toString(36).slice(5);
      // let file_name =
      //   file.name.substring(0, file.name.length - 3) +
      //   "-" +
      //   temp +
      //   "." +
      //   fileExt;
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;
      setLoadMessage("");
      let files1 = files;
      files1.push({
        name: newFileName,
        display_name: newFileName,
        file: file,
        ContentType: file.type,
      });
      setFiles(files1);
      if (side === "p") {
        let files1 = pcnFile;
        files1.push(newFileName);
        setPcnFile(files1);
      } else if (side === "a") {
        let files1 = appealFile;
        files1.push(newFileName);
        setApealFile(files1);
      }
    }
    Promise.all(uploadPromises)
      .then((responses) => {
        setType("success");
        hideLoading(layoutDispatch);
        setLoadMessage("File Uploaded !!");
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  const getUploadUrl = async (file, recordId) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/pcns/" + recordId + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedPcnType?.id === '' || selectedPcnType?.id === null || selectedPcnType?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        type: 'PCN type is required',
      }));
      return;
    }
    if (selectedVehicle?.id === '' || selectedVehicle?.id === null || selectedVehicle?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        vehicle: 'Registration is required',
      }));
      return;
    }
    if (selectedPcnType?.id == "1" && (selectedRoute?.id === '' || selectedRoute?.id === null || selectedRoute?.id === undefined)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        route: 'Route is required',
      }));
      return;
    }

    if (selectedPcnType?.id == "1" && (selectedDriver?.id === '' || selectedDriver?.id === null || selectedDriver?.id === undefined)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        driver: 'Driver is required',
      }));
      return;
    }

    if (selectedPostCode === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        postCode: 'Postcode is required',
      }));
      return;
    }

    if (selectedContra?.id === '' || selectedContra?.id === null || selectedContra?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contra: 'Contravention is required',
      }));
      return;
    }

    if (selectedFault?.id === '' || selectedFault?.id === null || selectedFault?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fault: 'Fault Type is required',
      }));
      return;
    }

    if (selectedAction?.id === '' || selectedAction?.id === null || selectedAction?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        action: 'Action is required',
      }));
      return;
    }

    if (selectedStatus?.id === '' || selectedStatus?.id === null || selectedStatus?.id === undefined) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        status: 'Status is required',
      }));
      return;
    }

    if (selectedAmount === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: 'Amount is required',
      }));
      return;
    }


    if (!selectedPcnDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pcnDate: 'PCN Date is required and must be a valid date',
      }));
      return;
    }

    if (!selectedPcnRecDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pcnRecDate: 'PCN Recieve Date is required and must be a valid date',
      }));
      return;
    }

    const dataToSend = {
      type: selectedPcnType?.id,
      route_id: selectedRoute?.id,
      driver_id: selectedDriver?.id,
      vehicle_id: selectedVehicle?.vehicle_id,
      netsuite_order_id: selectedCustomer?.internalId,
      fault: parseInt(selectedFault?.id),
      contravention: parseInt(selectedContra?.id),
      status: selectedStatus?.id,
      action: selectedAction?.id,
      notes: selectedNotes,
      amount: selectedAmount,
      postcode: selectedPostCode,
      pcn_date: moment(selectedPcnDate).format("YYYY-MM-DD HH:mm"),
      pcn_rec_date: moment(selectedPcnRecDate).format("YYYY-MM-DD"),
      pcn_file: pcnFile,
      pcn_appeal_file: appealFile,
      id: id
    };
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/pcn/pcn", {
        method: "PUT",
        body: JSON.stringify(dataToSend)
      })
        .then((response) => {
          if (response.ack === true) {
            // showSuccess(response.message);
            const uploadPromises = files.map(async (f, idx) => {
              try {
                const uploadUrl = await getUploadUrl(f, id);
                await uploadFileToS3(uploadUrl, f.file, f.file.type);
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });

            Promise.all(uploadPromises);
            hideLoading(layoutDispatch);
            navigate('/pcn')
          } else {
            hideLoading(layoutDispatch);
          }
        })
        .catch((err) => {
          hideLoading(layoutDispatch);
          console.log(err);
        });
    } else {

      showLoading(layoutDispatch);
      Auth.fetch("/pcn/pcn", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (response.ack === true) {
            const uploadPromises = files.map(async (f, idx) => {
              try {
                const uploadUrl = await getUploadUrl(f, response.record_id);
                await uploadFileToS3(uploadUrl, f.file, f.file.type);
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });

            Promise.all(uploadPromises);
            hideLoading(layoutDispatch);
            navigate('/pcn')
          } else {
            hideLoading(layoutDispatch);
          }
        })
        .catch((err) => {
          hideLoading(layoutDispatch);
          console.log(err);
        });
    }
  };

  const handleRemoveImage = (name, index, statename) => {
    if (statename === "pcnFile") {
      setPcnFile((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    } else if (statename === "appealFile") {
      setApealFile((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    }
  };

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  console.log('selectedDriverPhone', selectedDriver)

  const handleClosePcn = async () => {
    const requiredFields = [
      // selectedRoute?.id,
      // selectedDriver?.id,
      selectedVehicle?.id,
      selectedFault?.id,
      selectedContra?.id,
      selectedStatus?.id,
      selectedAction?.id,
      selectedAmount,
      selectedPostCode,
      selectedPcnDate,
      selectedPcnRecDate,
      selectedNotes
    ];

    if (selectedPcnType?.id == "1") {
      requiredFields.push(selectedRoute?.id);
      requiredFields.push(selectedDriver?.id);
    }

    if (
      requiredFields.every((field) => field !== '' && field !== null && field !== undefined) &&
      pcnFile.length > 0 &&
      appealFile.length > 0
    ) {
      const dataToSend = {
        id: id,
        closed: 1
      };
      const pcnUpdateData = {
        route_id: selectedRoute?.id,
        driver_id: selectedDriver?.id,
        vehicle_id: selectedVehicle?.vehicle_id,
        netsuite_order_id: selectedCustomer?.internalId,
        fault: parseInt(selectedFault.id),
        contravention: parseInt(selectedContra.id),
        status: selectedStatus.id,
        action: selectedAction.id,
        notes: selectedNotes,
        amount: selectedAmount,
        postcode: selectedPostCode,
        pcn_date: moment(selectedPcnDate).format("YYYY-MM-DD HH:mm"),
        pcn_rec_date: moment(selectedPcnRecDate).format("YYYY-MM-DD"),
        pcn_file: pcnFile,
        pcn_appeal_file: appealFile,
        id: id
      };

      ShowActionDialog(
        "Are you sure to want to Close this PCN?",
        "confirm",
        true,
        async function () {
          try {
            showLoading(layoutDispatch);
            const pcnResponse = await Auth.fetch("/pcn/pcn", {
              method: "PUT",
              body: JSON.stringify(pcnUpdateData)
            });

            if (pcnResponse.ack === true) {
              const uploadPromises = files.map(async (f, idx) => {
                try {
                  const uploadUrl = await getUploadUrl(f, pcnResponse.record_id);
                  await uploadFileToS3(uploadUrl, f.file, f.file.type);
                } catch (error) {
                  console.error("Error uploading file:", error);
                }
              });

              await Promise.all(uploadPromises);
              const closedStatusResponse = await Auth.fetch("/pcn/pcn-closed-status", {
                method: "PUT",
                body: JSON.stringify(dataToSend)
              });

              if (closedStatusResponse.ack === true) {
                setLoadMessage(closedStatusResponse.message);
                setType('success');
                if (selectedFault.title === "Driver" && selectedDriverPhone?.length > 0 && selectedStatus.title === 'Paid') {
                  const messagePayload = {
                    driver_name: selectedDriver?.driver_name,
                    amount: '£' + selectedAmount,
                    phone: selectedDriverPhone.trim(),
                    pcn_file: `${config.tms_uploads_cdn}/${config.env}/pcns/${id}/${pcnFile[0]}`,
                    pcn_id: id
                  };
                  try {
                    const messageApiResponse = await fetch("https://promotions.api-magnafoodservice.co.uk/api/send-tms-pcn-appeal-notifications", {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer 5|YzMkDJ11kIbbabpv1atryJYb6CETFCbRJY8i9QOs",
                        "Content-Type": "application/json"
                      },
                      body: JSON.stringify(messagePayload)
                    });

                    if (messageApiResponse.ack === true) {
                      setLoadMessage("Message sent on WhatsApp")
                      setType('success')

                    }
                  } catch (error) {
                    console.error(error);
                  }
                } else return;
              } else {
                setLoadMessage(closedStatusResponse.message);
                setType('error');
              }
            } else {
              hideLoading(layoutDispatch);
            }
          } catch (err) {
            console.log(err);
            setLoadMessage("An error occurred while processing the request");
            setType("error");
          } finally {
            hideLoading(layoutDispatch);
            navigate("/pcn");
          }
        })
    } else {
      let rand = Math.random() * 50;
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
      setLoadMessage("Please fill in all required fields, and make sure to add notes and files" + str);
      setType("error");
    }
  };
  // const setRouteData = async () => {
  //   if (vehicles.length == 0) return;
  //   // showLoading(layoutDispatch);

  //   let vehicle1 = vehicles.filter((r) => r.id == selectedVehicle.id);
  //   if (vehicle1[0]) {

  //     getAllDriversByVehicle(vehicle1[0].vehicle_id);
  //     // getAllRoutesByVehicle(vehicle1[0].vehicle_id);

  //     // const newDriver = drivers.find(
  //     //   (obj) => obj.id == vehicle1[0].driver_id
  //     // );
  //     // const newRoute = routes.find(
  //     //   (obj) => obj.id == vehicle1[0].route_id
  //     // );
  //     // setSelectedDriver(newDriver);
  //     // setSelectedRoute(newRoute);
  //   }

  // };


  const loadCustomersForRoute = async (route_id) => {
    if (!route_id || routes.length == 0) return;
    showLoading(layoutDispatch);

    let vehicle1 = vehicles.filter((r) => r.id == selectedVehicle.id);

    const dataToSend = {
      delivery_date: moment(selectedPcnDate).format("YYYY-MM-DD"),
      // route_status_id: vehicle1[0]?.route_status_id
      route_status_id: vehicle1[0]?.id,
      route_id: route_id
    };

    try {
      // Check if route_status_id exists in dataToSend
      if (dataToSend.route_status_id !== undefined) {
        const response = await Auth.fetch("/route/load-orders-by-route", {
          method: "POST",
          body: JSON.stringify(dataToSend)
        });

        if (response.ack === true) {
          hideLoading(layoutDispatch);
          setRouteOrders(response.route_orders);
        } else {
          setType("error");
          setLoadMessage(response.message);
          hideLoading(layoutDispatch);
        }
      } else {
        // Handle the case when route_status_id is not present in dataToSend
        hideLoading(layoutDispatch);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // React.useEffect(() => {
  //   if (selectedVehicle?.id !== "" && selectedVehicle?.id !== null && selectedVehicle?.id !== undefined) {
  //     setRouteData();
  //   } else {
  //     // Reset selectedDriver and selectedVehicle when selectedVehicle is empty
  //     setSelectedDriver('');
  //     setSelectedRoute('');
  //     setSelectedCustomer('');
  //   }
  // }, [selectedVehicle, vehicles]);

  React.useEffect(() => {
    if (selectedRoute?.id !== "" && selectedRoute?.id !== null && selectedRoute?.id !== undefined) {
      setSelectedCustomer('');
      loadCustomersForRoute(selectedRoute?.id);
    }
  }, [selectedRoute, routes]);

  React.useEffect(() => {
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/pcn/pcn?id=${id}`)
        .then((res) => {
          if (res?.ack) {
            setSelectedAmount(res?.pcn?.amount);
            setSelectedPostCode(res?.pcn?.postcode);

            const pcnType = pcnTypes.find(
              (obj) => obj.id == res?.pcn?.type
            );
            setSelectedPcnType(pcnType);


            const Contratype = pcnContraventionTypes.find(
              (obj) => obj.id == res?.pcn?.contravention
            );
            setSelectedContra(Contratype);
            const Faulttype = pcnFaultTypes.find(
              (obj) => obj.id == res?.pcn?.fault
            );
            setSelectedFault(Faulttype);
            const statustype = PcnStatusTypes.find(
              (obj) => obj.id == res?.pcn?.status
            );
            setSelectedStatus(statustype);

            const actionType = pcnActionTypes.find(
              (obj) => obj.id == res?.pcn?.action
            );
            setSelectedAction(actionType)
            setCustomerField(res?.pcn?.netsuite_order_id)
            setSelectedNotes(res?.pcn?.notes);
            setSelectedPcnDate(new Date(res?.pcn?.pcn_date));
            setSelectedPcnRecDate(new Date(res?.pcn?.pcn_rec_date));
            setPcnFile(JSON.parse(res?.pcn?.pcn_file));
            setApealFile(JSON.parse(res?.pcn?.pcn_appeal_file));
            setCloseStatus(res?.pcn?.closed)


            const myVehicle = res?.pcn?.vehicle_id
            const myDriver = res?.pcn?.driver_id
            const myRoute = res?.pcn?.route_id


            const datasend = {
              delivery_date: moment(res?.pcn?.pcn_date).format("YYYY-MM-DD"),
              vehicle_id: myVehicle,
              driver_id: myDriver,
              route_id: myRoute
            }

            showLoading(layoutDispatch);

            let url = res?.pcn?.type == 1 ? '/route/load-vehicles-by-route-date' : '/vehicle/vehicles';


            let vehicle1 = [];
            Auth.fetch(`${url}`, {
              method: "POST",
              body: JSON.stringify(datasend),
            }).then((res3) => {
              if (res3.ack) {
                vehicle1 = res3.vehicles;
                setVehicles(vehicle1)
                const currentVehicle = vehicle1.find(obj => obj.vehicle_id == myVehicle);
                setSelectedVehicle(currentVehicle)
                hideLoading(layoutDispatch);
              }
            }).catch((err) => {
              console.log(err)
            })


            showLoading(layoutDispatch);

            let url1 = "";
            let params = {};
            if (res?.pcn?.type == "1") {
              url1 = '/route/load-driver-by-vehicle';
              params.method = "POST";
              params.body = JSON.stringify(datasend);
            }
            else {
              url1 = '/driver/drivers';
            }

            Auth.fetch(`${url1}`, params).then((res5) => {
              if (res5.ack) {
                setDrivers(res5.drivers);
                const newDriver = res5.drivers.find(
                  (obj) => obj.id == myDriver
                );
                setSelectedDriver(newDriver);
                setSelectedDriverPhone(newDriver?.phone)
                hideLoading(layoutDispatch);
              }
            }).catch((err) => {
              console.log(err)
            });


            Auth.fetch("/route/load-route-by-vehicle", {
              method: "POST",
              body: JSON.stringify(datasend),
            }).then((res4) => {
              if (res4.ack) {
                setRoutes(res4.routes);
                const newRoute = res4.routes.find(
                  (obj) => obj.id == myRoute
                );
                setSelectedRoute(newRoute);
                hideLoading(layoutDispatch);
              }
            }).catch((err) => {
              console.log(err)
            });

            const currentVehicle = vehicle1.find(obj => obj.vehicle_id == myVehicle);
            if (currentVehicle)
              datasend.route_status_id = currentVehicle?.id;

            Auth.fetch("/route/load-orders-by-route", {
              method: "POST",
              body: JSON.stringify(datasend),
            }).then((res5) => {
              if (res5.ack) {
                setRouteOrders(res5.route_orders);
                const newCustomer = res5.route_orders.find(
                  (obj) => obj.internalId == res?.pcn?.netsuite_order_id
                );
                setSelectedCustomer(newCustomer)

                hideLoading(layoutDispatch);
              }
            }).catch((err) => {
              console.log(err)
            });

            // getAllDriversByVehicle(myVehicle, myDriver);

            // const myRoute = res?.pcn?.route_id;
            // showLoading(layoutDispatch);
            // Auth.fetch("/route/all-routes", {
            //   method: "POST",
            //   body: JSON.stringify(datasend),
            // }).then((res1) => {
            //   if (res1.ack) {
            //     setRoutes(res1.routes)
            //     const currentRoute = res1.routes.find(obj => obj.id == myRoute);
            //     setSelectedRoute(currentRoute)
            //     hideLoading(layoutDispatch);
            //   }
            // }).catch((err) => {
            //   console.log(err)
            // })
            // const myDriver = res?.pcn?.driver_id
            // showLoading(layoutDispatch);
            // Auth.fetch("/driver/drivers").then((res2) => {
            //   if (res2.ack) {
            //     setDrivers(res2.drivers)
            //     const currentDriver = res2.drivers.find(obj => obj.id == myDriver);
            //     setSelectedDriver(currentDriver)
            //     hideLoading(layoutDispatch);
            //   }
            // }).catch((err) => {
            //   console.log(err)
            // })

          } else {
            setLoadMessage('PCN not found !!');
            setType('error')
            setTimeout(() => {
              navigate('/pcn')
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectedPcnDate(moment().subtract(1, 'days').toDate());
      setSelectedPcnRecDate(new Date());

      // getAllDrivers();
      // getAllRoutes();
    }
  }, [id]);

  React.useEffect(() => {
    const isPcnDateValid = moment(selectedPcnDate).isValid();
    if (isPcnDateValid) {
      getAllVehicles();
    }
  }, [selectedPcnDate, selectedPcnType]);

  React.useEffect(() => {
    if (customerField && routeOrders.length) {
      const myCustomer = routeOrders && routeOrders.find(
        (obj) => obj?.internalId == customerField
      );
      setSelectedCustomer(myCustomer)
    }
  }, [customerField, routeOrders]);

  const handleContraventionChange = (e, values) => {
    setSelectedContra(values);
    const correspondingFault = pcnFaultTypes.find(
      (fault) => fault.id === values?.fault
    );
    const correspondingAction = pcnActionTypes.find(
      (action) => action.id === values?.action
    );
    setSelectedFault(correspondingFault);
    setSelectedAction(correspondingAction);
    setErrors((prevErrors) => ({
      ...prevErrors,
      contra: '',
    }));
  };

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <h1
            style={{ float: "left", marginBottom: "-5px", marginTop: "-5px" }}
          >
            {closeStatus === 1 ? "Closed PCN" : `${id > 0 ? "Update" : "Add"} PCN`}
          </h1>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <Stack spacing={2}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <label htmlFor="pcnDate">PCN Date:</label>
              {/* <DatePickerField
                disabled={closeStatus === 1}
                todayButton="Today"
                dateFormat="dd-MM-yyyy HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
                selected={selectedPcnDate}
                onChange={(date) => {
                  setSelectedPcnDate(date);
                  setSelectedVehicle('');
                  // getAllVehicles(date);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    pcnDate: '',
                  }));
                }}
              /> */}
              <AntDate
               format={'YYYY-MM-DD HH:mm'}
               style={{ width: 160}}
               disabled={closeStatus === 1}
               value={selectedPcnDate ? dayjs(selectedPcnDate) : null}

               onChange={(_, dateStr) => {
                setSelectedPcnDate(dateStr);
                setSelectedVehicle('');
                  // getAllVehicles(date);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    pcnDate: '',
                  }));
              }}
               showTime
               />
            </div>
            <FormHelperText style={{ color: 'red' }}>{errors.pcnDate}</FormHelperText>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <label htmlFor="pcnRecDate">PCN Received:</label>
              {/* <DatePickerField
                disabled={closeStatus === 1}
                todayButton="Today"
                dateFormat="dd-MM-yyyy"
                selected={selectedPcnRecDate}
                onChange={(date) => {
                  setSelectedPcnRecDate(date);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    pcnRecDate: '',
                  }));
                }}
              /> */}
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               disabled={closeStatus === 1}
               value={selectedPcnRecDate ? dayjs(selectedPcnRecDate) : null}

               onChange={(_, dateStr) => {
                setSelectedPcnRecDate(dateStr);
                setSelectedVehicle('');
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    pcnRecDate: '',
                  }));
              }}
               />
            </div>
            <FormHelperText style={{ color: 'red' }}>{errors.pcnRecDate}</FormHelperText>
            <Autocomplete
              id="type"
              options={pcnTypes}
              disabled={closeStatus === 1}
              getOptionLabel={(option) => option.title ? option.title : ''}
              value={selectedPcnType}
              onChange={(e, values) => {
                setSelectedPcnType(values);
                setSelectedVehicle('');
                // setSelectedDriver('');
                // setSelectedRoute('');
                // getAllDriversByVehicle(values?.vehicle_id);

                setErrors((prevErrors) => ({
                  ...prevErrors,
                  type: '',
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="PCN Type"
                  placeholder="PCN Type"
                  error={errors.type === "" ? false : true}
                  helperText={errors.type}
                />
              )}
            />
            {selectedPcnType?.id &&
              <Autocomplete
                id="vehicle"
                options={vehicles}
                disabled={closeStatus === 1}
                getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
                value={selectedVehicle}
                onChange={(e, values) => {
                  setSelectedVehicle(values);
                  // if (selectedPcnType?.id == "1") 
                  {
                    setSelectedDriver('');
                    setSelectedRoute('');
                    getAllDriversByVehicle(values?.vehicle_id);
                  }

                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    vehicle: '',
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Vehicle Reg"
                    placeholder="Vehicle Reg"
                    error={errors.vehicle === "" ? false : true}
                    helperText={errors.vehicle}
                  />
                )}
              />
            }
            {
              selectedVehicle?.id && (
                <>
                  <Autocomplete
                    id="driver"
                    options={drivers}
                    disabled={closeStatus === 1}
                    getOptionLabel={(option) => option.driver_name ? option.driver_name : ''}
                    value={selectedDriver}
                    onChange={(e, values) => {

                      setSelectedDriver(values);
                      if (selectedPcnType?.id == "1") {
                        setSelectedRoute('');
                        getAllRoutesByVehicle(selectedVehicle?.vehicle_id, values.id);
                      }
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        driver: '',
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Driver"
                        placeholder="Driver"
                        error={errors.driver === "" ? false : true}
                        helperText={errors.driver}
                      />
                    )}
                  />
                  {selectedPcnType?.id == "1" && (
                    <>

                      <Autocomplete
                        id="route"
                        options={routes}
                        disabled={closeStatus === 1}
                        getOptionLabel={(option) => option.title ? option.title + " (" + option.code + ")" : ''}
                        value={selectedRoute}
                        onChange={(e, values) => {
                          setSelectedRoute(values);
                          setSelectedCustomer('');
                          // loadCustomersForRoute(values?.id);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            route: '',
                          }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Route"
                            placeholder="Route"
                            error={errors.route === "" ? false : true}
                            helperText={errors.route}
                          />
                        )}
                      />

                      {routeOrders &&
                        <Autocomplete
                          id="Customer"
                          options={routeOrders}
                          disabled={closeStatus === 1}
                          getOptionLabel={(option) => option.shipZip ? option.shipZip + " - " + option.customerProjectId + " (" + option.customerName + ")" : ''}
                          value={selectedCustomer}
                          onChange={(e, values) => {
                            setSelectedCustomer(values);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Customer"
                              placeholder="Customer"
                            />
                          )}
                        />
                      }
                    </>
                  )}
                </>
              )
            }
            <TextField
              fullWidth
              variant="standard"
              disabled={closeStatus === 1}
              id="postCode"
              size="small"
              label="Postcode"
              type="text"
              required
              value={selectedPostCode}
              onChange={(e) => {
                setSelectedPostCode(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  postCode: '',
                }))
              }}
              error={errors.postCode === "" ? false : true}
              helperText={errors.postCode}
            />
            <Autocomplete
              id="Contravention-type"
              disabled={closeStatus === 1}
              options={pcnContraventionTypes}
              getOptionLabel={(option) => option.title}
              value={selectedContra}
              onChange={handleContraventionChange}
              // onChange={(e, values) => {
              //   setSelectedContra(values);
              //   setErrors((prevErrors) => ({
              //     ...prevErrors,
              //     contra: '',
              //   }))
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Contravention"
                  placeholder="Contravention"
                  error={errors.contra === "" ? false : true}
                  helperText={errors.contra}
                />
              )}
            />
            <Autocomplete
              id="fault-type"
              options={pcnFaultTypes}
              disabled={closeStatus === 1}
              getOptionLabel={(option) => option.title}
              value={selectedFault}
              onChange={(e, values) => {
                setSelectedFault(values);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  fault: '',
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Fault"
                  placeholder="Fault"
                  error={errors.fault === "" ? false : true}
                  helperText={errors.fault}
                />
              )}
            />
            <Autocomplete
              id="action-type"
              disabled={closeStatus === 1}
              options={pcnActionTypes}
              getOptionLabel={(option) => option.title}
              value={selectedAction}
              onChange={(e, values) => {
                setSelectedAction(values);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  action: '',
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Action"
                  placeholder="Action"
                  error={errors.action === "" ? false : true}
                  helperText={errors.action}
                />
              )}
            />
            <Autocomplete
              id="status-type"
              disabled={closeStatus === 1}
              options={PcnStatusTypes}
              getOptionLabel={(option) => option.title}
              value={selectedStatus}
              onChange={(e, values) => {
                setSelectedStatus(values);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  status: '',
                }))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Status"
                  placeholder="Status"
                  error={errors.status === "" ? false : true}
                  helperText={errors.status}
                />
              )}
            />
            <TextField
              fullWidth
              disabled={closeStatus === 1}
              id="amount"
              size="small"
              label="Amount"
              variant="standard"
              type="number"
              required
              value={selectedAmount}
              onChange={(e) => {
                setSelectedAmount(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  amount: '',
                }))
              }}
              error={errors.amount === "" ? false : true}
              helperText={errors.amount}
            />
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              disabled={closeStatus === 1}
              rows={4}
              style={{ width: "100%" }}
              value={selectedNotes}
              onChange={(e) => {
                setSelectedNotes(e.target.value);
              }}
            />
            <hr />
            <Button disabled={closeStatus === 1} sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }} variant="contained" component="label">
              Upload PCN
              <input
                type="file"
                hidden
                onChange={(e) => {
                  handleSubmitImage(e, "p");
                }}
              />
            </Button>
            <Button disabled={closeStatus === 1} sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }} variant="contained" component="label">
              Additional Documents
              <input
                type="file"
                capture
                hidden
                onChange={(e) => {
                  handleSubmitImage(e, "a");
                }}
              />
            </Button>

            {pcnFile?.length > 0 && <p>PCN File:</p>}
            <ul>
              {pcnFile && pcnFile.map((file, idx) => {
                const lastHyphenIndex = file.lastIndexOf('-')
                const dotBeforeExtensionIndex = file.lastIndexOf('.');
                const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    {
                      closeStatus !== 1 && (
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "pcnFile");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )
                    }
                    {
                      id > 0 ? (<a
                        href={`${config.tms_uploads_cdn}/${config.env}/pcns/${id}/${file}`}
                        target="_blank"
                      >
                        {newFileName}
                      </a>) : (<span
                      >
                        {newFileName}
                      </span>)
                    }
                  </li>
                );
              })}
            </ul>
            {appealFile?.length > 0 && <p>Additional Documents:</p>}
            <ul>
              {appealFile && appealFile.map((file, idx) => {
                const lastHyphenIndex = file.lastIndexOf('-')
                const dotBeforeExtensionIndex = file.lastIndexOf('.');
                const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    {
                      closeStatus !== 1 && (
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "appealFile");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )
                    }
                    {
                      id > 0 ? (<a
                        href={`${config.tms_uploads_cdn}/${config.env}/pcns/${id}/${file}`}
                        target="_blank"
                      >
                        {newFileName}
                      </a>) : (<span
                      >
                        {newFileName}
                      </span>)
                    }
                  </li>
                );
              })}
            </ul>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ float: "right", marginTop: "40px" }}>
            {
              (id > 0 && closeStatus !== 1) && <FormButton
                type="lock"
                onClick={handleClosePcn}
              >
                Close
              </FormButton>
            }
            <FormButton
              type="close"
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate("/pcn");
              }}
            >
              Cancel
            </FormButton>
            {
              closeStatus !== 1 && (
                <FormButton
                  type="save"
                  onClick={handleSubmit}
                >
                  Save
                </FormButton>
              )
            }
          </Box>
        </Grid>
      </Grid>
      <Message type={type} msg={loadMessage} />
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        cancelText='Cancel'
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default Index;
