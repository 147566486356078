import React, { useState } from 'react';

import { Pagination } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AuthService from '../../Components/auth/AuthService';
import { InputBaseField } from '../../Components/InputFields/InputFields';
import { FormButton } from '../../Components/UiElements/UiElements';
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import { ActionDialog } from '../../Components/actionDialog/ActionDialog';
import { Table, Button as AntButton, Tooltip, message } from 'antd/es';
import { DeleteFilled, EditFilled, HistoryOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import DriverHistoryModal from '../../Components/Modals/DriverHistoryModal';
import HelperHistoryModal from '../../Components/Modals/HelperHistoryModal';


const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
  },
  addIcon: {
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function HelperList() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [helpers, setHelpers] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);

  const [historyRecord, setHistoryRecord] = React.useState([]);
  const [helperHistoryModalOpen, setHelperHistoryModalOpen] = React.useState(false);
  const [selectedHelperID, setSelectedHelperID] = React.useState('');

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: '',
    type: '',
    isOpen: false
  });
  const ShowActionDialog = (message, type, isOpen, OnOkCallback, OnCancellCallback) => {
    setActionDialogProps(prevState => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  }

  const helperColumns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 135,
      // sorter: true,
      // render(record: any) {
      //   return <Capitalize>{record}</Capitalize>;
      // },
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 135,
      // sorter: true,
      // render(record: any) {
      //   return <Capitalize>{record}</Capitalize>;
      // },
    },
    {
      title: 'Maintenance Cost',
      // dataIndex: 'total_cost',
      // key: 'total_cost',
      width: 200,
      render: (record) => {
        return record?.total_cost
          ? "£" +
              Number(record?.total_cost).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
          : '';
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 500,
    },
    {
      title: 'Action',
      key: 'action',
      align: "center",
      dataIndex: '',
      width: '80px',
      render(record) {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}} onClick={(e) => e.stopPropagation()}>
            <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#006cb8"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];

  const getHistory = async (data) => {
    setSelectedHelperID(data?.id)
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/maintenance/maintenance-helper?helper_id=${data?.id}`)

      if(res?.ack) {
      console.log('res', res);
      if(res?.maintenance.length > 0) {
        setHistoryRecord(res?.maintenance)
        setHelperHistoryModalOpen(true)
      } else {
        setHistoryRecord([])
        setHelperHistoryModalOpen(true)
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getHelperData = async () => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.post(`/helper/driver-helpers`, {
        limit: limit,
        page: page,
        searchText: searchText,
      })
      if (res.ack) {
        setHelpers(res.driver_helpers);
        setTotalPages(Math.ceil(res.total_count / limit));
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch);
    }
  } 


  React.useEffect(() => {
    getHelperData()
    // showLoading(layoutDispatch);
    // Auth.post(`/helper/driver-helpers`, {
    //   limit: limit,
    //   page: page,
    //   searchText: searchText,
    // })
    //   .then((res) => {
    //     hideLoading(layoutDispatch);
    //     if (res.ack) {
    //       setHelpers(res.driver_helpers);
    //       setTotalPages(Math.ceil((res.total_count) / limit));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/helpers/${id}`)
  }


  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/helper/driver-helpers`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setHelpers(res.driver_helpers);
          setTotalPages(Math.ceil((res.total_count) / limit));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  React.useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };


  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog("Are you sure to want to delete this record?", "confirm", true, function () {

      const params = {
        method: "DELETE",
        body: JSON.stringify({
          id: id,
        }),
      };
      // setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch(`/helper/driver-helper`, params)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setType("info");
            setLoadMessage(res.message);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            // setLoading(false);
          } else {
            setLoadMessage("error");
            setLoadMessage(res.status);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })
  };

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Helpers</h2>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-helpers"}}>Import Helpers</Button> */}
      <div className={classes.tblTopDiv}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
            
          <AntButton
            type="primary"
            size="large"
            onClick={Search}
            style={{ marginLeft: '5px'}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          </div>
          </div>
        <div>
          <div className={classes.antAddIcon}>
            <PlusCircleFilled style={{ fontSize: "30px", color:"#1677FF"}}  onClick={() =>navigate("/helpers/new")} />
          </div>
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={helpers}
          style={{ overflowX: "auto" }}
          columns={helperColumns}
          pagination={false}
          footer={()=>`List of Helpers - Total Records: ${helpers.length === 0 ? "0" : helpers.length  }`}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => { actionDialogProps.onOk() }}
        onCancel={() => setActionDialogProps(prevState => ({
          ...prevState,
          isOpen: false,
        }))
        }
      />
      <HelperHistoryModal isModalOpen={helperHistoryModalOpen} setIsModalOpen={setHelperHistoryModalOpen} history={historyRecord} setHistory={setHistoryRecord} selectedHelperID={selectedHelperID} getData={getHelperData}/>
    </div>
  );
}
