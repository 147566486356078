import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import Message from "../../Components/common/Message";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
// import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { FormButton } from "../../Components/UiElements/UiElements";
import DatePicker from "react-date-picker";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import {
  vehicleTypes,
  maintenanceStatuses,
  maintenanceIssues,
  maintenanceSuppliers,
  maintenanceReportedBy,
  fileAttachments,
} from "../../Components/common/constants";
import AuthService from "../../Components/auth/AuthService";
import config from "./../../Components/config";
import { Table, Button as AntButton, DatePicker as AntDate } from "antd";
import { PrinterFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Auth = new AuthService();
let date = new Date();
function VehicleMaintenanceReport() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [vehicle, setVehicle] = React.useState("");
  const [vehicleType, setVehicleType] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [supplier, setSupplier] = React.useState([]);
  const [issueType, setIssueType] = React.useState([]);
  const [file, setFile] = React.useState("");
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [selectedRecordId, setSelectedRecordId] = React.useState(0);

  const [vehicles, setVehicles] = React.useState([]);
  const [maintenances, setMaintenances] = React.useState([]);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [screenWidth, setScreenWidth] = React.useState("");
  
  const [filesOpen, setFilesOpen] = React.useState(false);

  const vmrColumns = [
    {
      title: "Status",
      render(record) {
        return {
          props: {
            style: {background: record.background_color}
          },
          children: <span>
            {record.status != null
                    ? maintenanceStatuses.filter(
                      (obj) => obj.id == record.status
                    )[0].title
                    : "-"}
            </span>
        };
      }
    },
    {
      title: 'Registration No.	',
      dataIndex: 'registration_number',
      key: 'registration_number',
    },
    {
      title: 'Vehicle Type	',
      render: (record) => {
        return record.vehicle_type
        ? vehicleTypes.filter((obj) => obj.id == record.vehicle_type)[0].title
        : ""
      },
    },
    {
      title: 'Cost',
      render: (record) => {
        return record.cost
        ? "£" +
        Number(record.cost).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
        : ""}
    },
    {
        title: 'Reported By',
        render: (record) => {
          return record.reported_by
          ? maintenanceReportedBy.filter((obj) => obj.id == record.reported_by)[0].title : ""
        },
    },
    {
        title: 'Issue Type',
        render: (record) => {
          return record.issue_type
          ? maintenanceIssues.filter((obj) => obj.id == record.issue_type)[0].title : ""
        },
    },
    {
        title: 'Supplier',
        render: (record) => {
          return record.supplier
          ? maintenanceSuppliers.filter((obj) => obj.id == record.supplier)[0].title : ""
        },
    },
      
    {
      title: 'Created At',
      render: (record) => {
        return record.createdAt
        ? moment(record.createdAt).format("YYYY-MM-DD H:mm:ss")
        : ""
      },
    },
    {
      title: 'Files',
      render: (record) => {
        return JSON.parse(record.files).length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        ) : (
          <Button
            disabled
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        )
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes"
    },
  ];


  
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilesOpen = (row) => {
    if (row.files) {
      setSelectedFiles(JSON.parse(row.files));
      setSelectedRecordId(row.id);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    startDate,
    endDate,
    vehicle,
    vehicleType,
    status,
    supplier,
    issueType,
    file,
  ]);

  useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/vehicle/vehicles`, {
      // limit: limit,
      // page: page,
      // searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setVehicles(res.vehicles);
          search();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // showLoading(layoutDispatch);
    // Auth.fetch('/report/vehicle-maintenance', {
    //     method: "POST",
    //     body: {}
    // })
    //     .then((res) => {
    //         hideLoading(layoutDispatch);
    //         if (res.ack) {
    //             setMaintenances(res.maintenances);
    //         }
    //     });
  }, []);

  const search = () => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");

    if (vehicle) search.vehicle_id = vehicle?.id;
    if (vehicleType) search.vehicle_type = vehicleType?.id;
    if (status) search.status = status?.id;
    if (supplier) search.supplier = supplier?.id;
    if (issueType) search.type = issueType?.id;
    if (file) search.file = file?.id;

    showLoading(layoutDispatch);
    Auth.fetch("/report/vehicle-maintenance", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res.ack) {
        setMaintenances(res.maintenances);
      } else {
        setMaintenances([]);
      }
    });
  };

  const download = () => {
    let search = {};

    search.download = 1;

    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");

    if (vehicle) search.vehicle_id = vehicle?.id;
    if (status) search.status = status?.id;
    if (supplier) search.supplier = supplier?.id;
    if (issueType) search.type = issueType?.id;
    if (file) search.file = file?.id;

    setLoadMessage("");
    showLoading(layoutDispatch);
    Auth.fetch("/report/vehicle-maintenance", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res.ack) {
        console.log(res.file_url);
        window.open(res.file_url, "_blank", "noreferrer");
      } else {
        setType("error");
        setLoadMessage("Report download failed !!");
      }
    });
  };


  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('MainReportFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
  
    Object.assign(existingFilters, newValues);

    existingFilters.timestamp = new Date().getTime();
  
    const updatedFiltersString = JSON.stringify(existingFilters);
  
    localStorage.setItem('MainReportFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('MainReportFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    

    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {
      if (!existingFilters.vehicle) {
        existingFilters.vehicle = '';
      }
  
      if (!existingFilters.vehicleType) {
        existingFilters.vehicleType = '';
      }
  
      if (!existingFilters.status) {
        existingFilters.status = '';
      }
  
      if (!existingFilters.files) {
        existingFilters.files = '';
      }

      return existingFilters;
    } else {
      localStorage.removeItem('MainReportFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    setVehicle(initialState?.vehicle);
    setVehicleType(initialState?.vehicleType);
    setStatus(initialState?.status);
    setFile(initialState?.files);
    if (!initialState?.startDate) {
      const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      setStartDate(firstDayOfMonth);
      initialState.startDate = firstDayOfMonth
    } else {
      setStartDate(initialState.startDate);
    }

    if (!initialState?.endDate) {
      const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      setEndDate(lastDayOfMonth);
      initialState.endDate = lastDayOfMonth
    } else {
      setEndDate(initialState.endDate);
    }

    initialState.timestamp = new Date().getTime();
    updateFilters( initialState )

  }, []);

  return (
    <div>
      <Grid container sx={{ marginBottom: '15px'}} rowSpacing={1} columnSpacing={1}>
        <Grid item xs={8}>
          <h1 style={{ float: "left", margin: '1px 1px' }}>Vehicle Maintenance Report</h1>
        </Grid>
        <Grid item xs={4}>
          <AntButton
            style={{ float: "right" }}
            type="primary"
            size="large"
            onClick={download}
            icon={<PrinterFilled/>}
          >
            Download
          </AntButton>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={1.5}>
      <Grid item xs={12} sm={6} md={4} lg={2} xl={1.5}>
          {/* <DatePicker
            value={startDate}
            format={"dd-MM-yyyy"}
            onChange={(date) => {
              setStartDate(date);
              updateFilters({ startDate: date })
            }}
            className={classes.dateField}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={1.5}>
        {/* <DatePicker
            value={endDate}
            format={"dd-MM-yyyy"}
            onChange={(date) => {
              setEndDate(date);
              updateFilters({ endDate: date })
            }}
            className={classes.dateField}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                updateFilters({ endDate: dateStr })
              }}
              placeholder="Select End Date" 
               />
        </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.5} xl={1.5}>
          <Autocomplete
            id="registration_number"
            size="small"
            options={vehicles}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={vehicle}
            onChange={(e, values) => {
              setVehicle(values);
              updateFilters({ vehicle: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Registration No."
                placeholder="Registration No."
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.5} xl={1.5}>
          <Autocomplete
            id="vehicle-type"
            size="small"
            options={vehicleTypes}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={vehicleType}
            onChange={(e, values) => {
              setVehicleType(values);
              updateFilters({ vehicleType: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicle Type"
                placeholder="Vehicle Type"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.5} xl={2}>
          <Autocomplete
            id="status"
            size="small"
            options={maintenanceStatuses}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={status}
            onChange={(e, values) => {
              setStatus(values);
              updateFilters({ status: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                placeholder="Status"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            id="supplier"
            options={maintenanceSuppliers}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={supplier}
            onChange={(e, values) => {
              setSupplier(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supplier"
                placeholder="Supplier"
              />
            )}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            id="issue_type"
            options={maintenanceIssues}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={issueType}
            onChange={(e, values) => {
              setIssueType(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Issue Type"
                placeholder="Issue Type"
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={2.5} xl={1.5}>
          <Autocomplete
            id="file_attach"
            size="small"
            options={fileAttachments}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={file}
            onChange={(e, values) => {
              setFile(values);
              updateFilters({ files: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="File Attached"
                placeholder="File Attached"
              />
            )}
          />
        </Grid>
      </Grid>

      <Modal
        open={filesOpen}
        onClose={handleFilesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          overflowY: "auto",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          width: screenWidth < 610 ? "90%" : 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px'}}
        >
          <CloseIcon />
        </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download the files below
          </Typography>
          {selectedFiles.length > 0 &&
            selectedFiles.map((file, idx) => {
              return (
                <li key={idx}>
                  <a
                    href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                    target="_blank"
                  >
                    {file}
                  </a>
                </li>
              );
            })}
        </Box>
      </Modal>
      <br />
      <div className={classes.antTable}>
      <Table
          dataSource={maintenances}
          style={{ overflowX: "auto" }}
          columns={vmrColumns}
          pagination={false}
          footer={()=>`List of Maintenances - Total Records: ${maintenances.length === 0 ? "0" : maintenances.length  }`}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      
    </div>
  );
}

export default VehicleMaintenanceReport;
