import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Link from "@mui/material/Link";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  maintenanceSuppliers,
  maintenanceIssues,
  maintenanceStatuses,
  maintenanceReportedBy,
} from "../../Components/common/constants";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import DatePicker from "react-date-picker";
import CloseIcon from '@mui/icons-material/Close';
import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import qs from "query-string";
import { v4 as uuidv4 } from "uuid";
import config from "./../../Components/config";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CompareVehicleImage } from "../../Components/Modals";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close } from "@mui/icons-material";
import { appTheme } from "../../Components/Utils";
import { Table, Button as AntButton, DatePicker as AntDate } from "antd";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
    cursor: "pointer",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "39px",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  inputText: {
    ".MuiTextField-root": {
      width: '200px'
    }
  }
}));

const Auth = new AuthService();

export default function Maintenance(props) {
  const navigate = useNavigate();

  const classes = useStyles();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [maintenanceDate, setMaintenanceDate] = React.useState(new Date());
  const [currentDriverId, setCurrentDriverId] = React.useState("");
  const [currentDriver, setCurrentDriver] = React.useState("");
  const [currentRouteId, setCurrentRouteId] = React.useState("");
  const [currentRoute, setCurrentRoute] = React.useState("");

  const [history, setHistory] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [filesMisc, setFilesMisc] = React.useState([]);
  const [filesLeft, setFilesLeft] = React.useState([]);
  const [filesRight, setFilesRight] = React.useState([]);
  const [filesFront, setFilesFront] = React.useState([]);
  const [filesTail, setFilesTail] = React.useState([]);

  const [selectedRecordId, setSelectedRecordId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [selectedFilesMisc, setSelectedFilesMisc] = React.useState([]);
  const [selectedFilesFront, setSelectedFilesFront] = React.useState([]);
  const [selectedFilesRight, setSelectedFilesRight] = React.useState([]);
  const [selectedFilesLeft, setSelectedFilesLeft] = React.useState([]);
  const [selectedFilesTail, setSelectedFilesTail] = React.useState([]);

  const [status, setStatus] = React.useState("");
  const [supplier, setSupplier] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [reportedBy, setReportedBy] = React.useState("");
  const [cost, setCost] = React.useState("");

  const [selectedDays, setSelectedDays] = React.useState([]);
  const [maintenanceType, setMaintenanceType] = React.useState("");

  const [registrationNumberError, setRegistrationNumberError] =
    React.useState("");
  const [maintenanceTypeError, setMaintenanceTypeError] = React.useState("");
  const [bandError, setBandError] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [openCompareModal, setOpenCompareModal] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState("");

  React.useEffect(() => {
    getMaintenanceRoute(maintenanceDate);
  }, []);

  const getMaintenanceRoute = (date) => {
    Auth.fetch(
      `/maintenance/maintenance-route?date=${moment(date).format(
        "YYYY-MM-DD"
      )}&vehicle_id=${id}`
    )
      .then((res) => {
        if (res.ack) {
          if (res.maintenance_route) {
            setCurrentDriver(res.maintenance_route.driver);
            setCurrentDriverId(res.maintenance_route.driver_id);

            setCurrentRoute(res.maintenance_route.route);
            setCurrentRouteId(res.maintenance_route.route_id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilesOpen = (row) => {
    if (row?.files) {
      console.log(JSON.parse(row?.files));
      setSelectedRecordId(row.id);
      setSelectedFiles(JSON.parse(row?.files));
      setSelectedFilesMisc(row?.misc_files ? JSON.parse(row?.misc_files) : []);
      setSelectedFilesFront(
        row?.front_files ? JSON.parse(row?.front_files) : []
      );
      setSelectedFilesRight(
        row?.right_files ? JSON.parse(row?.right_files) : []
      );
      setSelectedFilesLeft(row?.left_files ? JSON.parse(row?.left_files) : []);
      setSelectedFilesTail(row?.tail_files ? JSON.parse(row?.tail_files) : []);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const handleClickOpen = () => {
    setOpen(true);
    setMaintenanceDate(new Date());
    setCurrentRoute("");
    setCurrentRouteId("");
    setCurrentDriver("");
    setCurrentDriverId("");
    setFiles([]);
    setFilesMisc([]);
    setFilesFront([]);
    setFilesRight([]);
    setFilesLeft([]);
    setFilesTail([]);
    setSelectedRecordId(0);
    setSelectedFiles([]);
    setSelectedFilesMisc([]);
    setSelectedFilesFront([]);
    setSelectedFilesRight([]);
    setSelectedFilesLeft([]);
    setSelectedFilesTail([]);
    setMaintenanceType("");
    setStatus("");
    setSupplier("");
    setCost("");
    setNotes("");
    setReportedBy("");
  };

  const handleClose = () => {
    setOpen(false);
    getMaintenanceData();
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const getMaintenanceData = () => {
    showLoading(layoutDispatch);
    Auth.fetch(`/maintenance/maintenance?vehilcle_id=${id}`)
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setRegistrationNumber(res.maintenance[0].registration_number);
          setHistory(res.maintenance);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (id > 0) {
      getMaintenanceData();
    }
  }, []);
  const showError = (message) => {
    setType("error");
    setLoadMessage(message);
  };
  const showSuccess = (message) => {
    setType("success");
    setLoadMessage(message);
  };

  const getUploadUrl = async (file, recordId) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/vehicles/" + recordId + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };
  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let rand = Math.random() * 50; // for unique message each time
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }

      if (notes === "") {
        setType("error");
        setLoadMessage("Please add notes" + str);
        return;
      } else {
        setLoadMessage("");
      }

      if (status === "") {
        setType("error");
        setLoadMessage("Please select status" + str);
        return;
      } else {
        setLoadMessage("");
      }

      setLoading(true);
      showLoading(layoutDispatch);

      const files1 = files.map((f) => f.name);
      const reqBody = {
        maintenance_date: moment(maintenanceDate).format("YYYY-MM-DD"),
        vehicle_id: id,
        current_driver_id: currentDriverId,
        current_route_id: currentRouteId,
        notes: notes,
        cost: cost,
        status: status,
        reported_by: reportedBy,
        files: files1,
        misc_files: filesMisc,
        front_files: filesFront,
        right_files: filesRight,
        left_files: filesLeft,
        tail_files: filesTail,
      };

      if (status <= 1) {
        reqBody.type = maintenanceType;
        reqBody.supplier = supplier;
      }

      const response = await Auth.fetch("/maintenance/maintenance", {
        method: "POST",
        body: JSON.stringify(reqBody),
      });

      if (response.ack === true) {
        // showSuccess(response.message);
        const uploadPromises = files.map(async (f, idx) => {
          try {
            const uploadUrl = await getUploadUrl(f, response.record_id);
            await uploadFileToS3(uploadUrl, f.file, f.file.type);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        });

        await Promise.all(uploadPromises);
        showSuccess(response.message);
        hideLoading(layoutDispatch);
        handleClose();
      } else {
        showError(response.message);
        hideLoading(layoutDispatch);
      }

      setLoading(false);
    } catch (error) {
      hideLoading(layoutDispatch);
      console.error("An error occurred:", error);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let rand = Math.random() * 50; // for unique message each time
  //   let str = " ";
  //   for (let i = 0; i < rand; i++) {
  //     str += " ";
  //   }

  //   if (notes === "") {
  //     setType("error");
  //     setLoadMessage("Please add notes" + str);
  //     return;
  //   } else {
  //     setLoadMessage("");
  //   }

  //   if (status === "") {
  //     setType("error");
  //     setLoadMessage("Please select status" + str);
  //     return;
  //   } else {
  //     setLoadMessage("");
  //   }

  //   setLoading(true);
  //   showLoading(layoutDispatch);
  //   let files1 = [];
  //   if (files.length > 0) {
  //     files.map((f) => {
  //       files1.push(f.name);
  //     });
  //   }
  //   let reqBody = {
  //     maintenance_date: moment(maintenanceDate).format("YYYY-MM-DD"),
  //     vehicle_id: id,
  //     current_driver_id: currentDriverId,
  //     current_route_id: currentRouteId,
  //     notes: notes,
  //     cost: cost,
  //     status: status,
  //     reported_by: reportedBy,
  //     files: files1,
  //     misc_files: filesMisc,
  //     front_files: filesFront,
  //     right_files: filesRight,
  //     left_files: filesLeft,
  //     tail_files: filesTail,
  //   };

  //   // if (status <= 1)
  //   {
  //     reqBody.type = maintenanceType;
  //     reqBody.supplier = supplier;
  //   }

  //   Auth.fetch("/maintenance/maintenance", {
  //     method: "POST",
  //     body: JSON.stringify(reqBody),
  //   })
  //     .then((res) => {
  //       if (res.ack === true) {
  //         setType("success");
  //         setLoadMessage(res.message);
  //         // window.location.reload();
  //         let uploadPromises = [];
  //         files.map((f, idx) => {
  //           let params = {
  //             bucket: config.tms_uploads_bucket,
  //             ContentType: f.file.type,
  //             // Key: file_name + "." + fileExt,
  //             Key: config.env + "/vehicles/" + res.record_id + "/" + f.name,
  //           };
  //           let promise = fetch(
  //             `${config.domain}/generate-put-url?${qs.stringify(params)}`,
  //             {
  //               headers: {
  //                 "Content-Type": params.ContentType,
  //               },
  //             }
  //           )
  //             .then((response) => response.json())
  //             // eslint-disable-next-line no-loop-func
  //             .then(async (res2) => {
  //               setLoadMessage("");
  //               const response = await fetch(res2.putURL, {
  //                 method: "put",
  //                 body: f.file,
  //                 headers: {
  //                   "Content-Type": params.ContentType,
  //                 },
  //               });
  //               const data = response;
  //               if (data.status === 200) {
  //                 hideLoading(layoutDispatch);
  //                 handleClose();
  //               }
  //             })
  //             .catch((error) => {
  //               console.error("Error:", error);
  //             });
  //           uploadPromises.push(promise);
  //         });

  //         Promise.all(uploadPromises)
  //           .then((responses) => {
  //             setType("success");
  //             hideLoading(layoutDispatch);
  //             setLoadMessage("File Uploaded !!");
  //             handleClose();
  //           })
  //           .catch((error) => {
  //             console.error("Error uploading files:", error);
  //           });
  //         setLoading(false);
  //       } else {
  //         setType("error");
  //         setLoadMessage(res.message);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleSubmitImage = (e, side) => {
    e.preventDefault();

    // This function is only for populating images arrays

    console.log("inside handle subbmit");
    let uploadPromises = [];
    let acceptedFiles = e.target.files;
    showLoading(layoutDispatch);
    var i = 0;
    for (let file of acceptedFiles) {
      // var fileExt = file.name.split(".").pop();
      // let temp = Math.random().toString(36).slice(5);
      // let file_name =
      //   file.name.substring(0, file.name.length - 3) +
      //   "-" +
      //   temp +
      //   "." +
      //   fileExt; // uuidv4();

      // let params = {
      //   bucket: config.tms_uploads_bucket,
      //   ContentType: file.type,
      //   // Key: file_name + "." + fileExt,
      //   Key: config.env + "/vehicles/" + file_name,
      // };

      setLoadMessage("");
      // let promise = fetch(
      //   `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      //   {
      //     headers: {
      //       "Content-Type": params.ContentType,
      //     },
      //   }
      // )
      //   .then((response) => response.json())
      //   // eslint-disable-next-line no-loop-func
      //   .then(async (res) => {
      //     setLoadMessage("");
      // const response = await fetch(res.putURL, {
      //   method: "put",
      //   body: file,
      //   headers: {
      //     "Content-Type": params.ContentType,
      //   },
      // });
      // const data = response;
      // if (data.status === 200) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;
      let files1 = files;
      files1.push({
        name: newFileName,
        display_name: newFileName,
        file: file,
        ContentType: file.type,
      });
      setFiles(files1);
      if (side === "l") {
        let files1 = filesLeft;
        files1.push(newFileName);
        setFilesLeft(files1);
      } else if (side === "r") {
        let files1 = filesRight;
        files1.push(newFileName);
        setFilesRight(files1);
      } else if (side === "f") {
        let files1 = filesFront;
        files1.push(newFileName);
        setFilesFront(files1);
      } else if (side === "t") {
        let files1 = filesTail;
        files1.push(newFileName);
        setFilesTail(files1);
      } else if (side === "m") {
        let files1 = filesMisc;
        files1.push(newFileName);
        setFilesMisc(files1);
      }
      // }
      // })
      // .catch((error) => {
      //   console.error("Error:", error);
      // });
      // uploadPromises.push(promise);
    }
    // This function is only for populating images arrays
    Promise.all(uploadPromises)
      .then((responses) => {
        setType("success");
        hideLoading(layoutDispatch);
        setLoadMessage("File Uploaded !!");
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };
  const handleOpenCompareModal = () => {
    setOpenCompareModal(true);
  };
  const handleRemoveImage = (name, index, statename) => {
    if (statename === "filesTail") {
      setFilesTail((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    } else if (statename === "filesMisc") {
      setFilesMisc((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    } else if (statename === "filesLeft") {
      setFilesLeft((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    } else if (statename === "filesRight") {
      setFilesRight((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    } else if (statename === "filesFront") {
      setFilesFront((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
    }
  };

  const eqpMainColumns = [
    {
    title: "Status",  
    render(record) {
      return {
        props: {
          style: {background: record.background_color}
        },
        children: <span>
          {record.status != null
          ? maintenanceStatuses.filter(
            (obj) => obj.id == record.status
          )[0].title
          : "-"}
          </span>
      };
    }
    },
    {
      title: 'Date',
      render: (record) => {
        return record.maintenance_date ? moment(record.maintenance_date).format("DD-MMM-YYYY") : ""
      }
    },
    {
      title: 'Driver',
      dataIndex: "driver",
      key: "driver",
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
    },
    {
      title: 'Reported By',
      render: (record) => {
        return record.reported_by
         ? maintenanceReportedBy.filter(
          (obj) => obj.id == record.reported_by
           )[0].title
         : ""
      }
    },
    {
      title: "Maintenance Type",
      render: (record) => {
        return record.type
        ? maintenanceIssues.filter((obj) => obj.id == record.type)[0]
            .title
        : ""
      }
    },
    {
      title: "Supplier",
      render: (record) => {
        return record.supplier
        ? maintenanceSuppliers.filter(
            (obj) => obj.id == record.supplier
          )[0].title
        : ""
      }
    },
    {
      title: 'Cost',
      render: (record) => {
        return record?.cost
          ? "£" +
              Number(record?.cost).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
          : '';
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Updated At',
      render: (record) => {
        return record.createdAt
        ? moment(record.createdAt).format("YYYY-MM-DD H:mm:ss")
        : ""
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Files',
      render: (record) => {
        return JSON.parse(record.files).length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        ) : (
          <Button
          disabled
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        )
      },
    },
  ];



  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> Maintenance History</h1>

        {/* <Grid container spacing={2}>
          <Grid item xs={11.5} sm={6} md={4} lg={3} xl={2}>
            <TextField
              size="small"
              value={registrationNumber}
              onChange={(event) => {
                setRegistrationNumber(event.target.value);
              }}
              label="Registration Number"
              id="registrationNumber"
              disabled={true}
              error={registrationNumberError === "" ? false : true}
              helperText={registrationNumberError}
            />
          </Grid>
          <Grid item xs={11.5} sm={6} md={8} lg={9} xl={10} sx={{}}>
            <AntButton
            
              // variant="contained"
              type="primary"
              size="large"
              onClick={handleOpenCompareModal}
              // sx={{
              //   background: "#006cb8", ":hover": {background: "white" },
              //   marginTop: screenWidth >= 600 ? "8px" : null,
              //   marginLeft: screenWidth <= 600 ? "8px" : null,
              // }}
            >
              Compare Vehicle Images
            </AntButton>
          </Grid>
        </Grid> */}
        <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <div className={classes.inputText}>
            <TextField
              size="small"
              value={registrationNumber}
              onChange={(event) => {
                setRegistrationNumber(event.target.value);
              }}
              label="Registration Number"
              id="registrationNumber"
              disabled={true}
              error={registrationNumberError === "" ? false : true}
              helperText={registrationNumberError}
            />
            </div>
            
          <AntButton
            
            // variant="contained"
            type="primary"
            size="large"
            onClick={handleOpenCompareModal}
            style={{ marginLeft: '5px'}}
            // sx={{
            //   background: "#006cb8", ":hover": {background: "white" },
            //   marginTop: screenWidth >= 600 ? "8px" : null,
            //   marginLeft: screenWidth <= 600 ? "8px" : null,
            // }}
          >
            Compare Vehicle Images
          </AntButton>
          </div>
          </div>

        <div className={classes.tblTopDiv}>
          <div className={classes.addIcon}>
            <AddCircleIcon fontSize="large" sx={{':hover': { color: "#4096ff "}, color: '#1677FF'}} onClick={handleClickOpen} />
            <Dialog open={open}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={9.5}>
                    <DialogTitle>
                      Maintenance Update <br /> ({registrationNumber})
                    </DialogTitle>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Close
                      sx={{ margin: "12px", cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </Grid>
                </Grid>
              </Box>
              <DialogContent>
                <DialogContentText>Please fill below form</DialogContentText>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  sx={{ marginTop: "0px" }}
                >
                  <Grid item>
                    {/* <DatePicker
                      label={"Noor"}
                      value={maintenanceDate}
                      format={"dd-MM-yyyy"}
                      onChange={(date) => {
                        setMaintenanceDate(date);
                        getMaintenanceRoute(date);
                      }}
                      className={classes.dateField}
                    /> */}
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%'}}
                      value={maintenanceDate ? dayjs(maintenanceDate) : null}
                      onChange={(_, dateStr) => {
                        setMaintenanceDate(dateStr);
                        getMaintenanceRoute(dateStr)
                      }}
                      placeholder="Select date" 
                      getPopupContainer={trigger => trigger.parentNode}
                      />
                    
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      value={currentDriver}
                      style={{ width: "100%" }}
                      disabled={true}
                      label="Driver"
                      id="driver"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      value={currentRoute}
                      style={{ width: "100%" }}
                      disabled={true}
                      label="Route"
                      id="route"
                    />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel id="reportedBy-label">Reported By</InputLabel>
                      <Select
                        labelId="reportedBy-label"
                        id="reportedBy"
                        value={reportedBy}
                        label="reportedBy"
                        onChange={(e) => {
                          setReportedBy(e.target.value);
                        }}
                      >
                        {maintenanceReportedBy.map((ss) => {
                          return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        id="status"
                        value={status}
                        label="status"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        {maintenanceStatuses.map((ss) => {
                          return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel id="type-label">Maintenance Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="maintenance-type"
                        value={maintenanceType}
                        label="type"
                        onChange={(e) => {
                          setMaintenanceType(e.target.value);
                        }}
                      >
                        {maintenanceIssues.map((s) => {
                          return <MenuItem value={s.id}>{s.title}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel id="supplier-label">Supplier</InputLabel>
                      <Select
                        labelId="supplier-label"
                        id="supplier"
                        value={supplier}
                        label="status"
                        onChange={(e) => {
                          setSupplier(e.target.value);
                        }}
                      >
                        {maintenanceSuppliers.map((s) => {
                          return <MenuItem value={s.id}>{s.title}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      value={cost}
                      type={"number"}
                      style={{ width: "100%" }}
                      onChange={(event) => {
                        setCost(event.target.value);
                      }}
                      label="Cost"
                      id="cost"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="outlined-multiline-static"
                      label="Notes"
                      multiline
                      rows={4}
                      value={notes}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }} variant="contained" component="label">
                      Upload Files (Misc)
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => {
                          handleSubmitImage(e, "m");
                        }}
                      />
                    </Button>
                    <hr />
                    <Button variant="contained" component="label" sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }}>
                      Upload Images (Front)
                      <input
                        type="file"
                        multiple
                        capture
                        hidden
                        onChange={(e) => {
                          handleSubmitImage(e, "f");
                        }}
                      />
                    </Button>
                    <br />
                    <br />
                    <Button variant="contained" component="label" sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }}>
                      Upload Images (Right)
                      <input
                        type="file"
                        multiple
                        capture
                        hidden
                        onChange={(e) => {
                          handleSubmitImage(e, "r");
                        }}
                      />
                    </Button>
                    <br />
                    <br />
                    <Button variant="contained" component="label" sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }}>
                      Upload Images (Left)
                      <input
                        type="file"
                        multiple
                        capture
                        hidden
                        onChange={(e) => {
                          handleSubmitImage(e, "l");
                        }}
                      />
                    </Button>
                    <br />
                    <br />
                    <Button variant="contained" component="label" sx={{ background: "#1677FF", ":hover": {background: "#4096ff" } }}>
                      Upload Images (Tail)
                      <input
                        type="file"
                        multiple
                        capture
                        hidden
                        onChange={(e) => {
                          handleSubmitImage(e, "t");
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                {/* <input type="file" multiple hidden onChange={handleSubmitImage} accept="image/*" /> */}
                {filesMisc.length > 0 && <p>Misc:</p>}
                <ul>
                  {filesMisc.map((file, idx) => {
                    const lastHyphenIndex = file.lastIndexOf('-')
                    const dotBeforeExtensionIndex = file.lastIndexOf('.');
                    const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                    return (
                      <li key={idx}>
                        {" "}
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "filesMisc");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {newFileName}
                      </li>
                    );
                  })}
                </ul>
                {filesFront.length > 0 && <p>Front Side:</p>}
                <ul>
                  {filesFront.map((file, idx) => {
                    const lastHyphenIndex = file.lastIndexOf('-')
                    const dotBeforeExtensionIndex = file.lastIndexOf('.');
                    const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                    return (
                      <li key={idx}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "filesFront");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {newFileName}
                      </li>
                    );
                  })}
                </ul>
                {filesRight.length > 0 && <p>Right Side:</p>}
                <ul>
                  {filesRight.map((file, idx) => {
                    const lastHyphenIndex = file.lastIndexOf('-')
                    const dotBeforeExtensionIndex = file.lastIndexOf('.');
                    const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                    return (
                      <li key={idx}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "filesRight");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {newFileName}
                      </li>
                    );
                  })}
                </ul>
                {filesLeft.length > 0 && <p>Left Side:</p>}
                <ul>
                  {filesLeft.map((file, idx) => {
                    const lastHyphenIndex = file.lastIndexOf('-')
                    const dotBeforeExtensionIndex = file.lastIndexOf('.');
                    const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                    return (
                      <li key={idx}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "filesLeft");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>{" "}
                        {newFileName}
                      </li>
                    );
                  })}
                </ul>
                {filesTail.length > 0 && <p>Tail Side:</p>}
                <ul>
                  {filesTail.map((file, idx) => {
                    const lastHyphenIndex = file.lastIndexOf('-')
                    const dotBeforeExtensionIndex = file.lastIndexOf('.');
                    const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                    return (
                      <li key={idx}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx, "filesTail");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {newFileName}
                      </li>
                    );
                  })}
                </ul>

                {/* <ul>
                {files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <a
                        href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${file}`}
                        target="_blank"
                      >
                        {file}
                      </a>
                    </li>
                  );
                })}
              </ul> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* ==================popup for Showing uploaded Image==================== */}
        <Modal
          open={filesOpen}
          onClose={handleFilesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              overflowY: "auto",
              maxHeight: "90vh",
              transform: "translate(-50%, -50%)",
              width: screenWidth < 610 ? "90%" : 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px'}}
        >
          <CloseIcon />
        </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Download the files below
            </Typography>
            {selectedFilesMisc.length > 0 && (
              <p style={{ padding: "0" }}>Misc Files</p>
            )}
            {selectedFilesMisc.length > 0 &&
              selectedFilesMisc.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
            {selectedFilesFront.length > 0 && (
              <p style={{ padding: "0" }}>Front Files</p>
            )}
            {selectedFilesFront.length > 0 &&
              selectedFilesFront.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
            {selectedFilesRight.length > 0 && (
              <p style={{ padding: "0" }}>Right Files</p>
            )}
            {selectedFilesRight.length > 0 &&
              selectedFilesRight.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}

            {selectedFilesLeft.length > 0 && (
              <p style={{ padding: "0" }}>Left Files</p>
            )}
            {selectedFilesLeft.length > 0 &&
              selectedFilesLeft.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
            {selectedFilesTail.length > 0 && (
              <p style={{ padding: "0" }}>Tail Files</p>
            )}
            {selectedFilesTail.length > 0 &&
              selectedFilesTail.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/vehicles/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
          </Box>
        </Modal>
        <div className={classes.antTable}>
        <Table 
        columns={eqpMainColumns}
        style={{ overflowX: "auto" }}
        dataSource={history}
        pagination={false}
        footer={()=> `List of Maintenances - Total Recored: ${history.length}`}
        />
      </div> 

        {/* <TableContainer component={Paper}>
          <Table
            sx={{ width: "100%" }}
            aria-label="caption table"
            size="small"
            dense
          >
            <caption>List of Maintenances</caption>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Driver</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Route</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Reported By
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Maintenance Type
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Supplier</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Cost</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Updated By</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Updated At</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Notes</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Files</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row, index) => (
                <TableRow
                  style={
                    index % 2
                      ? { background: "#D3D3D3" }
                      : { background: "white" }
                  }
                  key={row.id}
                  className={classes.row}
                >
                  <TableCell style={{ background: row.background_color }}>
                    {row.status
                      ? maintenanceStatuses.filter(
                          (obj) => obj.id == row.status
                        )[0].title
                      : ""}
                  </TableCell>
                  <TableCell>{row.maintenance_date}</TableCell>
                  <TableCell>{row.driver}</TableCell>
                  <TableCell>{row.route}</TableCell>
                  <TableCell>
                    {row.reported_by
                      ? maintenanceReportedBy.filter(
                          (obj) => obj.id == row.reported_by
                        )[0].title
                      : ""}{" "}
                  </TableCell>
                  <TableCell>
                    {row.type
                      ? maintenanceIssues.filter((obj) => obj.id == row.type)[0]
                          .title
                      : ""}{" "}
                  </TableCell>
                  <TableCell>
                    {row.supplier
                      ? maintenanceSuppliers.filter(
                          (obj) => obj.id == row.supplier
                        )[0].title
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.cost
                      ? "£" +
                        Number(row.cost).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : ""}
                  </TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>
                    {row.createdAt
                      ? moment(row.createdAt).format("YYYY-MM-DD H:mm:ss")
                      : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "180px",
                    }}
                  >
                    {row.notes}
                  </TableCell>
                  <TableCell>
                    {row.files ? (
                      <Button
                        onClick={() => {
                          handleFilesOpen(row);
                        }}
                      >
                        {JSON.parse(row.files).length + "-Files"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Message type={type} msg={loadMessage} />
        </TableContainer> */}
        <Message type={type} msg={loadMessage} />
      </Box>

      {/* ================================CompareVechileModal =========================*/}
      {openCompareModal && (
        <CompareVehicleImage
          handleClose={() => setOpenCompareModal(false)}
          open={openCompareModal}
          VehicleRestoration={registrationNumber}
          vehicleId={id}
        />
      )}
    </>
  );
}
