import * as React from "react";
import { Grid, TextField } from "@mui/material";
import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  statuses,
  userTypes,
  userRolesTypes,
} from "../../Components/common/constants";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { FormButton } from "../../Components/UiElements/UiElements";

const Auth = new AuthService();

export default function User() {
  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [userType, setUserType] = React.useState({ id: 1, title: "Admin" });
  const [userStatus, setUserStatus] = React.useState({
    id: "1",
    title: "Active",
  });
  const [userRole, setUserRole] = React.useState();
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [userTypeError, setUserTypeError] = React.useState("");
  const [userStatusError, setUserStatusError] = React.useState("");
  // const [userPasswordError, setUserPasswordError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/user/user?id=${id}`)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setFirstName(res.user.first_name);
            setLastName(res.user.last_name);
            setPhone(res.user.phone);
            setEmail(res.user.email);
            const userRoles1 = userTypes.find((obj) => obj.id == res.user.type);
            setUserType(userRoles1);

            const userStatus1 = statuses.find(
              (obj) => obj.id == res.user.status
            );
            setUserStatus(userStatus1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onClickCancel = () => {
    navigate("/users");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!(id > 0)) {
    //   if (code === "") {
    //     setCodeError("Required");
    //     return;
    //   } else {
    //     setCodeError("");
    //   }
    // }

    if (firstName === "") {
      setFirstNameError("Required");
      return;
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Required");
      return;
    } else {
      setLastNameError("");
    }

    if (email === "") {
      setEmailError("Required");
      return;
    } else {
      setEmailError("");
    }

    if (phone === "") {
      setPhoneError("Required");
      return;
    } else {
      setPhoneError("");
    }

    if (
      userType == undefined ||
      userType.id == undefined ||
      userType.id == ""
    ) {
      setUserTypeError("Required");
      return;
    } else {
      setUserTypeError("");
    }

    if (
      userStatus == undefined ||
      userStatus.id == undefined ||
      userStatus.id == ""
    ) {
      setUserStatusError("Required");
      return;
    } else {
      setUserStatusError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      const data = {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        type: userType.id,
        status: userStatus.id,
        email: email,
        id: id,
        password: password,
      };
      if (userRole) data.role_id = userRole.id;
      Auth.fetch("/user/user", {
        method: "PUT",
        body: JSON.stringify(data),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        password: password,
        type: userType.id,
        status: userStatus.id,
      };
      if (userRole) data.role_id = userRole.id;
      Auth.fetch("/user/user", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeUserType = (event, values) => {
    setUserType(values);
  };

  const onChangeUserRole = (event, values) => {
    setUserRole(values);
  };

  const onChangeUserStatus = (event, values) => {
    setUserStatus(values);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} User</h1>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={10} sm={4}>
            <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              label="First Name"
              id="firstName"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />
            <br />
            <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              label="Last Name"
              id="lastName"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
            <br />
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label="Email"
              id="email"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            <br />
            <TextField
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              label="Password"
              type="password"
              id="password"
              // error={lastNameError === "" ? false : true}
              // helperText={lastNameError}
            />

            <br />
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              label="Phone Number"
              id="phone"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
            <br />

            <Autocomplete
              id="user_Type"
              options={userTypes}
              getOptionLabel={(option) => option.title}
              value={userType}
              onChange={onChangeUserType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Type"
                  placeholder="User Type"
                  error={userTypeError === "" ? false : true}
                  helperText={userTypeError}
                />
              )}
            />
            <br />
            {userType.id === 2 && (
              <>
                <Autocomplete
                  id="user_Type_Role"
                  options={userRolesTypes}
                  getOptionLabel={(option) => option.title}
                  value={userRole}
                  onChange={onChangeUserRole}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="User Role"
                      placeholder="User Role"
                    />
                  )}
                />
                <br />
              </>
            )}

            <Autocomplete
              id="user_status"
              options={statuses}
              getOptionLabel={(option) => option.title}
              value={userStatus}
              onChange={onChangeUserStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Status"
                  placeholder="Status"
                  error={userStatusError === "" ? false : true}
                  helperText={userStatusError}
                />
              )}
            />
            <br />
          </Grid>
        </Grid>
        <Box style={{ float: "right", marginTop: "40px" }}>
          <FormButton type="close" onClick={onClickCancel}>
            Cancel
          </FormButton>
          <FormButton type="save" onClick={handleSubmit}>
            Save
          </FormButton>
        </Box>

        <Message type={type} msg={loadMessage} />
      </Box>
    </div>
  );
}
