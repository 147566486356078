import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Alert from "@mui/material/Alert";
import AuthService from "../../Components/auth/AuthService";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import Link from "@mui/material/Link";
import LockIcon from '@mui/icons-material/Lock';
import Message from "../../Components/common/Message";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import config from "../../Components/config";
import { makeStyles } from "@mui/styles";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.magnafoodservice.co.uk/">
      
        Magna Foods
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
  },
}));

const theme = createTheme();


export default function SignIn(props) {
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  

  const [error, setError] = useState(null);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("info");
  const Auth = new AuthService();

  const isAuthenticated = Auth.loggedIn();
  
  useEffect(() => {
    console.log("load signin ...");
    if (isAuthenticated) return window.location  = "/home";
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    let flg = false;
    if (email === "") {
      setEmailError("Required");
      flg = true;
    } else {
      setEmailError("");
    }

    if (password === "") {
      setPasswordError("Required");
      flg = true;
    } else {
      setPasswordError("");
    }

    if (flg) {
      return;
    }
    setLoadMessage("");
    Auth.login(email, password)
      .then((res) => {
        if (res.ack) {
          window.location = "/home";
          localStorage.removeItem('PcnFilters');
          localStorage.removeItem('AccidentFilters');
          localStorage.removeItem('MainReportFilters')
          localStorage.removeItem('MaintenanceListFilters')
        } else {
          setType("error");
          setLoadMessage(res.message);
          setError(res.status);
        }
      })
      .catch((err) => {
        console.log(err);

        setType("error");
        setLoadMessage(err.message);
        setError(err.status);
      });

    // console.log( 'Email:', email.value, 'Password: ', password.value);
    // if (this.isValid())
    // Auth.fetch("loginStudents", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    //   body: new URLSearchParams({
    //     school_id: 1,
    //     role: "Teacher",
    //     password: password,
    //     username: username,
    //   }),
    // })
    //   .then((res) => {
    //     console.log("res", res);
    //     if (res.status) {
    //       localStorage.setItem("user_id", res.data.user_id);
    //       // props.history.replace("/media");
    //       window.location.reload();
    //     }
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  }

  // const handleChangeSchool = (event) => {
  //   console.log("inside hanelschoolchange, ", event.target.value);
  // let value = event.target.value;
  // console.log(value);
  // console.log(schools);
  // let school = schools.filter((obj) => {
  //   return obj.name == value;
  // });

  // console.log(school);
  // setSchool(school);
  // getTeachers(school);
  // };

  return (
    (!isAuthenticated && 
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">

      <CssBaseline />
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Alert
            onClose={() => {
              setError(null);
            }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            name="email"
            label="Email"
            type="text"
            id="email"
            error={emailError === "" ? false : true}
            helperText={emailError}
          />
          <TextField
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={passwordError === "" ? false : true}
            helperText={passwordError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
        </form>
        <Message type={type} msg={loadMessage} />
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </ThemeProvider>
    )
  );
}
