import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Collapse, Button, Input, Checkbox, Select } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, ExpandAltOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { StockChangeReasons } from "../../Components/common/constants";
import { netsuite_url } from "../../Components/config";


const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();


const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
  },
}));

const StockDiscrepanciesReport = ({ stockDiscrepanciesData, setStockDiscrepanciesData, deliveryQuantities1 }) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");
  const [deliveryQuantities, setDeliveryQuantities] = React.useState({});
  // const [disabledInputs, setDisabledInputs] = React.useState({});
  // const [tableCheckbox, setTableCheckbox] = React.useState({});
  
  const [returnedStockCheckbox, setReturnedStockCheckbox] = React.useState({});
  const [returnedStockDisableInputs, setReturnedStockDisableInputs] = React.useState({});

  useEffect(() => {
    if (stockDiscrepanciesData.length > 0) {
        const initialDeliveryQuantities = {};
        
        stockDiscrepanciesData.forEach((discrepancy) => {
            discrepancy.tableData.forEach((item) => {
                initialDeliveryQuantities[item.itemPalletId] = {
                    item_pallet_id: item.itemPalletId,
                    returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                    returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                };

                if (item.returnedQuantityToStockStatus === item.returnedQuantity && item.returnedQuantityToStockStatus > 0 && item.returnedQuantity > 0) {
                    setReturnedStockDisableInputs((prevState) => ({
                        ...prevState,
                        [item.rank]: true,
                    }));
                    setReturnedStockCheckbox((prevState) => ({
                        ...prevState,
                        [item.rank]: true,
                    }));
                }
            });
        });
        
        setDeliveryQuantities(initialDeliveryQuantities);
    }
}, [stockDiscrepanciesData]);


  const { Panel } = Collapse;
  const { Option } = Select;

  const readOnly = localStorage.getItem("read_only") === "true";
  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };

  const handleReturnToStockQuantityChange = (value, record, commentId) => {
    const isFullQuantity = value === record.returnedQuantity;
    if (value === "full") {
      value = record.returnedQuantity;
      commentId = 3;
    }
    if (value === "notFull") {
      value = 0;
      commentId = null;
    }
    const updatedDeliveryQuantities = {
      ...deliveryQuantities,
      [record.itemPalletId]: {
        ...deliveryQuantities[record.itemPalletId],
        returned_quantity_to_stock: value,
        returned_quantity_to_stock_status: commentId,
      },
    };
    if (isFullQuantity) {
      setReturnedStockDisableInputs((prevState) => ({
        ...prevState,
        [record.rank]: true,
      }));
      setReturnedStockCheckbox((prevState) => ({
        ...prevState,
        [record.rank]: true,
      }));
    }
    setDeliveryQuantities(updatedDeliveryQuantities);
  };

  const handleReturndStockCheckboxChange = (isChecked, rank) => {
    setReturnedStockDisableInputs((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));

    setReturnedStockCheckbox((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));
  };

  const handleSwitchToggle = async (itemPalletID, returnedQuantity, checked) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataToSend = {
      pallet_item_id: itemPalletID,
      returned_quantity: returnedQuantity,
      returned_quantity_approved_rec: checked ? 1 : 0
    }
    try {
      const response = await Auth.fetch("/route/change-quantity-received-status", {
        method: "POST",
        body: JSON.stringify(dataToSend)
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55
    },
    {
      title: "Shop Code",
      // dataIndex: "customerName",
      // key: "customerName",
      render: (text,record) => {
        return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
      },
      width: 100
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },
    {
      title: "Invoice",
      // dataIndex: "invoiceNo",
      render: (text, record) => {
        return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.invoiceInternalId}`} target="_blank">{record.invoiceNo}</a></span>;
      },
      width: 100
    },
    {
      title: "Item Code",
      dataIndex: "itemId",
      key: "itemId",
      width: 100
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 200
    },
    {
      title: "Ordered QTY", 
      dataIndex: "quantity",
      key: "quantity",
      width: 100
    },
    {
      title: "Returned QTY", 
      dataIndex: "orgReturnedQuantity",
      key: "orgReturnedQuantity",
      width: 100,
      align: 'center'
    },
    {
      title: "Reason",
      dataIndex: "status",
      key: "status",
      width: 150

    },
    {
      title: "Warehouse Received QTY (Quarantine)",
      dataIndex: "returnedQuantity",
      render: (text, record) => (
       
          <span 
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: record.returnedQuantity != record.orgReturnedQuantity ? '#ff0000' : '',
            fontSize: '16px',
            fontWeight: "bold"
          }}
        >{record.returnedQuantity || 0}</span>
          
      ),
      width: 120
    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Input
            type="number"
            style={{ width: "75px", marginRight: "5px" }}
            value={
              deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock || 0
            }
            disabled={returnedStockDisableInputs[record.rank]}
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value);
              handleReturnToStockQuantityChange(
                newValue,
                record,
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status
              );
            }}
          />
          <Checkbox
            checked={returnedStockCheckbox[record.rank]}
            onChange={(e) => {
              const isChecked = e.target.checked;
              handleReturndStockCheckboxChange(isChecked, record.rank);
              handleReturnToStockQuantityChange(
                isChecked ? "full" : "notFull",
                record,
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status
              );
            }}
            disabled={readOnly}
          >
            Full
          </Checkbox>
        </div>
      ),
      width: 140,
      align: 'center'

    },
    {
      title: "Stock Difference",
      render: (record) => {
        const returnedQuantityToStock = deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock || 0;
        const difference =  record.orgReturnedQuantity - returnedQuantityToStock;
        return <span>{difference}</span>;
      },
      width: 100,
      align: 'center'
    },
    {
      title: "Stock Difference Status",
      width: 150,
      render: (record) => {
        console.log('record', record)
        const stockStatus =
          deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status;
        return (
          <Select
            style={{
              width: "100%",
            }}
            value={stockStatus}
            placeholder="Select Status"
            onChange={(value) =>
              handleReturnToStockQuantityChange(
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock,
                record,
                value
              )
            }
            disabled={readOnly}
          >
            {StockChangeReasons.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        );
      },
    },
    // {
    //   title: "Returned to Stock QTY", 
    //   dataIndex: "returned_quantity_to_stock",
    //   render: (text, record) => (
    //    <span>{deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock || 0}</span>
    //   ),
    //   width: 100,
    //   align: 'center'
    // },
    // {
    //   title: "Returned Stock Status", 
    //   dataIndex: "returned_quantity_to_stock_status",
    //   render: (text, record) => (
    //    <span>{deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status }</span>
    //   ),
    //   width: 100,
    //   align: 'center'
    // },
    
  ];

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let stockDiscrepanciesData1 = [...stockDiscrepanciesData];

    if (expandAll) {
      stockDiscrepanciesData1.map((route, rIdx) => {
            route.expanded = false;
        })
    }
    else {
      stockDiscrepanciesData1.map((route, rIdx) => {
            route.expanded = true;
        });
    }
    setStockDiscrepanciesData(stockDiscrepanciesData1);
    setExpandAll(!expandAll);
};

const handleRouteExpand = (route_idx) => {
  const updatedData = [...stockDiscrepanciesData];

  updatedData[route_idx].expanded = !updatedData[route_idx].expanded;

  setStockDiscrepanciesData(updatedData);
}

useEffect(() => {
  if (stockDiscrepanciesData.length === 1) {
    setStockDiscrepanciesData(prevData => {
      return prevData.map(item => ({
        ...item,
        expanded: true
      }));
    });
  }
}, []);

const saveInfo = async () => {
  setIsLoading(true)
  const data = Object.values(deliveryQuantities)
  const dataToSend = {
    discrepancies: data
  }
  let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
  try {
    const response = await Auth.fetch(
      "/route/update-stock-discrepancies",
      {
        method: "POST",
        body: JSON.stringify(dataToSend),
      }
    );
    if (response.ack) {
      setType("success");
      setLoadMessage(response.message + str);
      // setIsLoading(false)
    } else {
      setType("error");
      setLoadMessage(response.message + str);
    }
  } catch (err) {
    console.log(err)
  } finally {
    setIsLoading(false)
  }
}

// const downloadCSVFile = (id) => {
//   const dataObject = stockDiscrepanciesData.find((item, idx) => idx === id);
//   console.log('dataObject', dataObject)

//   // Extract formatted_date and driver fields
//   const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes

//   // Define the filename
//   const filename = `${formattedDate}_${dataObject.driverRoute}_StockDiscrepancy.csv`;

//   // Extract all fields from the dataObject, excluding 'expanded' and 'formatted_date'
//   const fields = Object.keys(dataObject).filter(field => field !== 'expanded' && field !== 'formatted_date' && field !== 'routeStatusID');
  
//   // Define a mapping object to rename tableData headers
//   const tableDataHeaderMapping = {
//       rank: 'S No',
//       customerName: 'Shop Code',
//       customerProjectId: 'Shop Name',
//       itemId: 'Item ID',
//       itemName: 'Item Name',
//       // palletOrderID: 'Pallet Order ID',
//       // customerInternalId: 'Customer Internal Id',
//       invoiceNo: 'Invoice No',
//       quantity: 'Ordered Quantity',
//       orgReturnedQuantity: 'Returned Quantity',


//   };

//   // Define fields to exclude from tableData
//   const excludedTableDataFields = ['invoiceInternalId', 'palletOrderID', 'customerInternalId', 'itemPalletId', 'returnedQuantityApprovedRec', 'returnedQuantityApprovedSup', 'returnedQuantityToStockStatus', 'returnedQuantityToStock' ];

//   // Format data into CSV string
//   let csvData = '';
  
//   // Loop through each field
//   fields.forEach(field => {
//       // Rename 'route' field to 'Route No'
//       let fieldName; 
//       // = field === 'route' ? 'Route No' : field;

//       if (field === 'route') {
//         fieldName = 'Route No';
//       }

//       if (field === 'driver') {
//         fieldName = 'Driver';
//       }

//     if (field === 'truck') {
//         fieldName = 'Truck Reg.';
//       }
//       if (field === 'driverRoute') {
//         fieldName = 'Route';
//       }
      
//       // Check if the field is tableData
//       if (field === 'tableData') {
//           // Extract tableData headers
//           const tableHeaders = Object.keys(dataObject[field][0]);
//           // Map headers to their renamed counterparts and exclude unwanted fields
//           const renamedHeaders = tableHeaders
//               .filter(header => !excludedTableDataFields.includes(header))
//               .map(header => tableDataHeaderMapping[header] || header);
//           // Add tableData headers to CSV
//           csvData += `${renamedHeaders.join(',')}\n`;

//           // Loop through each row in tableData and add to CSV
//           dataObject[field].forEach(row => {
//               // Exclude unwanted fields from tableData
//               const rowData = Object.entries(row)
//                   .filter(([key]) => !excludedTableDataFields.includes(key))
//                   .map(([key, value]) => value);
//               csvData += `${rowData.join(',')}\n`;
//           });
//       } else {
//           // For other fields, add key and value to CSV
//           csvData += `${fieldName},${dataObject[field]}\n`;
//       }
//   });

//   // Create a Blob object from the CSV data
//   const blob = new Blob([csvData], { type: 'text/csv' });

//   // Create a temporary URL for the Blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a link element to trigger the download
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', filename);

//   // Append the link to the body and click it to trigger the download
//   document.body.appendChild(link);
//   link.click();

//   // Clean up: remove the link and revoke the URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// };

// const downloadCSVFile = (id) => {
//   const dataObject = stockDiscrepanciesData.find((item, idx) => idx === id);
//   console.log('dataObject', dataObject);

//   // Extract formatted_date and driver fields
//   const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes

//   // Define the filename
//   const filename = `${formattedDate}_${dataObject.driverRoute}_StockDiscrepancy.csv`;

//   // Define fields to exclude
//   const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

//   // Define a mapping object to rename tableData headers
//   const tableDataHeaderMapping = {
//     rank: 'S No',
//     customerName: 'Shop Code',
//     customerProjectId: 'Shop Name',
//     itemId: 'Item ID',
//     itemName: 'Item Name',
//     invoiceNo: 'Invoice No',
//     quantity: 'Ordered Quantity',
//     orgReturnedQuantity: 'Returned Quantity',
//     returnedQuantity: 'Warehouse Received QTY (Quarantine)',
//     returnedQuantityToStock: "Returned To Stock QTY"
//   };

//   // Define fields to exclude from tableData
//   const excludedTableDataFields = [
//     'invoiceInternalId',
//     'palletOrderID',
//     'customerInternalId',
//     'itemPalletId',
//     'returnedQuantityApprovedRec',
//     'returnedQuantityApprovedSup',
//     'returnedQuantityToStockStatus',
//     // 'returnedQuantityToStock',
//   ];

//   // Separate simple fields and tableData
//   const fields = Object.keys(dataObject).filter(field => !excludedFields.includes(field));
//   const simpleFields = fields.filter(field => field !== 'tableData');
//   const tableDataField = fields.find(field => field === 'tableData');

//   // Format simple fields data into CSV string
//   let csvData = '';

//   simpleFields.forEach(field => {
//     let fieldName = field;

//     if (field === 'route') fieldName = 'Route No';
//     if (field === 'driver') fieldName = 'Driver';
//     if (field === 'truck') fieldName = 'Truck Reg.';
//     if (field === 'driverRoute') fieldName = 'Route';

//     csvData += `${fieldName},${dataObject[field]}\n`;
//   });

//   // Process tableData if it exists
//   if (tableDataField) {
//     // Extract tableData headers
//     const tableHeaders = Object.keys(dataObject[tableDataField][0]);
//     // Map headers to their renamed counterparts and exclude unwanted fields
//     const renamedHeaders = tableHeaders
//       .filter(header => !excludedTableDataFields.includes(header))
//       .map(header => tableDataHeaderMapping[header] || header);
//     // Add tableData headers to CSV
//     csvData += `${renamedHeaders.join(',')}\n`;

//     // Loop through each row in tableData and add to CSV
//     dataObject[tableDataField].forEach(row => {
//       const rowData = Object.entries(row)
//         .filter(([key]) => !excludedTableDataFields.includes(key))
//         .map(([key, value]) => value);
//       csvData += `${rowData.join(',')}\n`;
//     });
//   }

//   // Create a Blob object from the CSV data
//   const blob = new Blob([csvData], { type: 'text/csv' });

//   // Create a temporary URL for the Blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a link element to trigger the download
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', filename);

//   // Append the link to the body and click it to trigger the download
//   document.body.appendChild(link);
//   link.click();

//   // Clean up: remove the link and revoke the URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// };

const downloadCSVFile = (id) => {
  const dataObject = stockDiscrepanciesData.find((item, idx) => idx === id);
  console.log('dataObject', dataObject);

  // Extract formatted_date and driver fields
  const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes

  // Define the filename
  const filename = `${formattedDate}_${dataObject.driverRoute}_StockDiscrepancy.csv`;

  // Define fields to exclude
  const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

  // Define a mapping object to rename tableData headers
  const tableDataHeaderMapping = {
    rank: 'S No',
    customerName: 'Shop Code',
    customerProjectId: 'Shop Name',
    itemId: 'Item Code',
    itemName: 'Item Name',
    invoiceNo: 'Invoice No',
    quantity: 'Ordered Quantity',
    orgReturnedQuantity: 'Returned Quantity',
    returnedQuantity: 'Warehouse Received QTY (Quarantine)',
    returnedQuantityToStock: "Returned To Stock QTY",
    status: 'Reason',
    returnedQuantityToStockStatus: "Stock Difference Status" ,
    stockDiff : "Stock Difference",
  };

  // Define fields to exclude from tableData
  const excludedTableDataFields = [
    'invoiceInternalId',
    'palletOrderID',
    'customerInternalId',
    'itemPalletId',
    'returnedQuantityApprovedRec',
    'returnedQuantityApprovedSup',
    
  ];

  // Define the sequence of simple fields
  const simpleFieldsSequence = ['route', 'driver', 'truck', 'driverRoute'];

  // Define the sequence of table fields
  const tableFieldsSequence = ['rank', 'customerName', 'invoiceNo', 'itemId', 'itemName',  'quantity', 'orgReturnedQuantity', 'status', 'returnedQuantity', 'returnedQuantityToStock', 'stockDiff', 'returnedQuantityToStockStatus'];

  // Format simple fields data into CSV string
  let csvData = '';

  simpleFieldsSequence.forEach(field => {
    if (excludedFields.includes(field)) return;

    let fieldName = field;
    if (field === 'route') fieldName = 'Route No';
    if (field === 'driver') fieldName = 'Driver';
    if (field === 'truck') fieldName = 'Truck Reg.';
    if (field === 'driverRoute') fieldName = 'Route';

    csvData += `${fieldName},${dataObject[field]}\n`;
  });

  // Process tableData if it exists
  const tableDataField = 'tableData';
  if (dataObject[tableDataField]) {
    // Map headers to their renamed counterparts and exclude unwanted fields
    const renamedHeaders = tableFieldsSequence
      .filter(header => !excludedTableDataFields.includes(header))
      .map(header => tableDataHeaderMapping[header] || header);
    
    // Add tableData headers to CSV
    csvData += `${renamedHeaders.join(',')}\n`;

    // Loop through each row in tableData and add to CSV
    dataObject[tableDataField].forEach(row => {
      const rowData = tableFieldsSequence
        .filter(key => !excludedTableDataFields.includes(key))
        .map(key => {
          if (key === 'returnedQuantityToStockStatus') {
              return row[key]  ? StockChangeReasons.filter((obj) => obj.id == row[key])[0].title  : '';
          }
          if(key === 'stockDiff') {
            return row['orgReturnedQuantity'] - row['returnedQuantityToStock']
          }
          return row[key];
      });
      csvData += `${rowData.join(',')}\n`;
    });
  }

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append the link to the body and click it to trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};


const genExtra = (id) => (
  <Button
    type="primary"
    size="large"
    onClick={(e) => {
      e.stopPropagation()
      downloadCSVFile(id)
    }
    } 
  >
    Download
  </Button>
);


  return (
    <div className={classes.dataSheet}>
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
      <h3>Stock Discrepancies</h3>
      <Button 
      type="primary" 
      style={{ float: 'right'}} 
      onClick={onClickExpand}
      icon={<ExpandAltOutlined />}>
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </div>
      
      {stockDiscrepanciesData?.length > 0 &&
        stockDiscrepanciesData?.map((data, index) => (
          <>
            <div className="view-page">              
              <Collapse style={{margin: '10px 0px'}} activeKey={data.expanded ? index.toString() : ''} onChange={() => { handleRouteExpand(index)}}>
              <Panel header={
              <>
              <span style={{ fontWeight: 'bold'}}>Route: </span>
              <span style={{ marginRight: '10px'}}>{data.driverRoute}</span>
              <span style={{ fontWeight: 'bold'}}>Driver: </span>
              <span style={{ marginRight: '10px'}}>{data.driver}</span>
              <span style={{ fontWeight: 'bold'}}>Vehicle Reg: </span>
              <span >{data.truck}</span>
              </>
              } extra={genExtra(index)} key={index}>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Route No"
                        value={data.route}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Truck"
                        value={data.truck}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Route"
                        value={data.driverRoute}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Driver"
                        value={data.driver}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Button type="primary" size="large" loading={isLoading} style={{ float: 'right', marginTop: "10px"}} onClick={saveInfo}> Update Discrepancies</Button>
              <Table
                  style={{ overflowX: "auto", marginTop: "10px"}}
                  columns={dataCol}
                  bordered
                  dataSource={data.tableData}
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />
              </Panel>
              </Collapse>

            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default StockDiscrepanciesReport;
