import React, { useEffect, useState } from "react";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import Message from "../../Components/common/Message";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";

import {tms_uploads_cdn, env} from "../../Components/config"

import DriverSheetReport from "./DriverSheetReport";
import GoodsReturnReport from "./GoodsReturnReport";
import StockDiscrepanciesReport from "./StockDiscrepanciesReport";
import { Divider, Select, Button as AntButton, Result, Collapse, Row, Col, Card, Switch, Descriptions, Tag, Image, Table, Modal, Badge, DatePicker as AntDate } from "antd";
import { deliveryStatuses, userRolesTypes, vehicleDefects } from "../../Components/common/constants";
import CashUpReport from "./CashUpReport";
import FinancialSummaryReport from "./FinancialSummaryReport";
import AmountDiscrepanciesReport from "./AmountDiscrepanciesReport";
import { useSelector } from "react-redux";
import { CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseCircleOutlined, CloseOutlined, EditFilled, EditOutlined, WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  
  dataSheet: {
    "& .ant-checkbox .ant-checkbox-inner": {
      width: "36px",
      height: "36px"
    },
    "& .ant-checkbox+span": {
      fontSize: '16px',
      paddingTop: '6px'
    },
    "& .ant-checkbox-checked .ant-checkbox-inner:after": {
      width: '10px',
      height: '22px'
    },
    "& .ant-switch .ant-switch-inner": {
      transition: 'padding-inline-start 0.0s ease-in-out, padding-inline-end 0.0s ease-in-out;'
    }
  },
  dateField: {
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    },
    "& .react-date-picker__button svg ": {
      stroke: "#b6babf",
    }
  },
  selectStyle: {
    "& .ant-select .ant-select-selector": {
      borderBottom: '1px solid #d9d9d9',
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderRadius: '0px'
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));


const Auth = new AuthService();
export default function DefectReport() {

  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [allDefectSheets, setallDefectSheets] = React.useState([]);
  const [allDefectSheetsCopy, setallDefectSheetsCopy] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState('');


  const [routes, setRoutes] = React.useState([]);
  const [selectedDriverRoute, setSelectedDriverRoute] = useState([]);

  const { Panel } = Collapse;
  const { Option } = Select;


  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some(item => item.title === "Finance")
  const includesRouting = matchedRoles.some(item => item.title === "Routing")
  const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
  const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")


  const loadRoutes = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    setSelectedDriverRoute([])
    setRoutes([]);

    showLoading(layoutDispatch)
    try {
      const routeRes = await Auth.post(`/route/load-locked-routes`, {
        delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      })
      if(routeRes.ack) {
        setRoutes(routeRes?.routes)
        try {
          const res = await Auth.fetch(`/route/get-defect-sheet-report?delivery_date=${moment(deliveryDate).format("YYYY-MM-DD")}`);
          if (res?.ack) {
            if(res?.vehicle_defect_sheets.length > 0) {
              const filtered = res.vehicle_defect_sheets
            const transformed = transformData(filtered)
            console.log('transformed',transformed);
  
            transformed.map((item)=>(
              item.defect_sheets.forEach(sheet => {
                sheet.defect = createDefectObjects(sheet);
            })
            ))
              res.vehicle_defect_sheets.forEach(sheet => {
                  sheet.defect = createDefectObjects(sheet);
              });
  
              transformed.forEach((main) => {
                main.defect_sheets.forEach((subMain) => {
                  subMain.defect = subMain.defect.filter((defectObj) => {
                    if (defectObj.status === 0) {
                      return true;
                    }
              
                    if (defectObj.status === 1 && (defectObj.notes || defectObj.images.length > 0)) {
                      return true;
                    }
              
                    return false;
                  });
                });
              });
  
              setallDefectSheets(transformed)
              setallDefectSheetsCopy(transformed)
              setLoadMessage(res.message + str)
              setType('success')
            } else {
              setLoadMessage('No Record Found' + str)
              setType('error')
            }
            
          } else {
            setLoadMessage(res?.message + str)
            setType('error')
          }
      } catch (err) {
          console.log(err);
      }
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  }


function transformData(input) {
  const transformedData = {};

  input.forEach(obj => {
    const { route_status_id, ...rest } = obj;

    if (route_status_id !== null && route_status_id !== undefined) {
      // Check if a key with current `route_status_id` exists in `transformedData`
      if (!transformedData[route_status_id]) {
        // If not, create a new object for this `route_status_id`
        transformedData[route_status_id] = {
          route: obj.route,
          route_status_id: route_status_id,
          rs_id: obj.rs_id,
          driver: obj.driver,
          registration_number: obj.registration_number,
          defect_sheets: []
        };
      }

      // Push the defect sheet object to the appropriate `defect_sheets` array based on `type`
      transformedData[route_status_id].defect_sheets.push({
        route: obj.route,
        route_status_id: route_status_id,
        rs_id: obj.rs_id,
        driver: obj.driver,
        registration_number: obj.registration_number,
        type: obj.type,
        ...rest
      });
    } else {
      // If route_status_id is null or undefined, create a separate entry for each object
      transformedData[`null_${Math.random()}`] = {
        route: obj.route,
        route_status_id: null,
        rs_id: obj.rs_id,
        driver: obj.driver,
        registration_number: obj.registration_number,
        defect_sheets: [{
          route: obj.route,
          route_status_id: null,
          rs_id: obj.rs_id,
          driver: obj.driver,
          registration_number: obj.registration_number,
          type: obj.type,
          ...rest
        }]
      };
    }
  });

  // Convert the transformedData object into an array of values
  return Object.values(transformedData);
}


function createDefectObjects(sheet) {
  const defectArray = [];

  for (const key in sheet) {
      if (sheet.hasOwnProperty(key) && key.endsWith('_not_road_worthy')) {
          const baseKey = key.replace('_not_road_worthy', '');

          const matchingDefect = vehicleDefects.find(defect => defect.key === baseKey);
          const defectObject = {
              title: baseKey,
              route_status_id: sheet[`route_status_id`],
              rs_id: sheet[`rs_id`],
              status: sheet[baseKey],
              notWorthy: sheet[`${baseKey}_not_road_worthy`],
              images: sheet[`${baseKey}_images`] ? JSON.parse(sheet[`${baseKey}_images`]) : [],
              notes: sheet[`${baseKey}_notes`] ? sheet[`${baseKey}_notes`] : null,
              required: matchingDefect ? matchingDefect.required : false
          };
          defectArray.push(defectObject);
      }
  }

  return defectArray;
}


console.log('defects', allDefectSheets)
const defaultActiveKeys = allDefectSheets.map((_, index) => index.toString());

useEffect(() => {
  if (selectedDriverRoute.length) {
    let filteredData = allDefectSheets?.filter((data) => {
      const routeStatusIDString = String(data.rs_id);
      const selectedRouteStrings = selectedDriverRoute.map(String);
      return selectedRouteStrings.includes(routeStatusIDString);
    });
    setallDefectSheetsCopy(filteredData);
  } else {
    setallDefectSheetsCopy(allDefectSheets);
  }
}, [selectedDriverRoute])


const getStatusText = (status) => {
  switch(status) {
    case 0:
      return <Tag style={{ fontSize: '16px'}} color="error">Failed</Tag>;
    case 1:
      return <Tag style={{ fontSize: '16px'}} color="success">Passed</Tag>;
    default:
      return 'N/A';
  }
};

const getWorthyStatusText = (status) => {
  switch(status) {
    case 0:
      return <Tag style={{ fontSize: '16px'}} color="success">Yes</Tag>;
    case 1:
      return <Tag style={{ fontSize: '16px'}} color="error">No</Tag>;
   
    default:
      return 'N/A';
  }
};

const handleFilesOpen = (row) => {
  if (row) {
    setSelectedRecord(row);
  }
  setFilesOpen(true);
};
const handleFilesClose = () => setFilesOpen(false);

  const dataCol = [
    {
      title: "Title",
      render(record) {
        let backgroundColor;
        if (record?.status == 0) {
          if(record?.required) {
            backgroundColor = "red";
          } else {
            backgroundColor = "#ff90a4";
          }

        } 
        if (record?.status == 1 && (record?.notes != null || record?.images.length > 0)) {
          backgroundColor = "orange";
        }
    
        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>
          {record?.title?.replace(/_/g, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          {/* {record.required && <span style={{ color: 'red' }}> *</span>} */}
          </>,
        };
      },
      width: 100
    },
    {
      title: 'Action',
      align: 'center',
      width: 150,
      render (record) {
        return (
          <>
          <Badge color="#1890ff" dot={record.images.length > 0 || record?.notes}>
          <EditFilled
            onClick={() => {
              handleFilesOpen(record);
            }}
            style={{ fontSize: '25px', cursor: "pointer", color: "grey" }}
          />
          </Badge>
          </>
        ) 
      },
    },
  ]

  const genExtraContent = (idx, data) => {
    const NullID = data?.route_status_id == null || data?.route_status_id == null 
    const noReturn = data?.defect_sheets?.every(sheet => sheet.type !== 2);
    return (
      <>
        {
          NullID ? (
            <div style={{ display: 'flex', alignItems: 'center'}}>
      <CloseCircleFilled style={{ color: 'red', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'red', fontSize: '14px'}}>No Checkout</span>
      </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center'}}>
      <CheckCircleFilled style={{ color: 'green', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'green', fontSize: '14px'}}>Checkout Done</span>
      </div>
          )
        }
        {
          noReturn ? (
            <div style={{ display: 'flex', alignItems: 'center'}}>
      <CloseCircleFilled style={{ color: 'red', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'red', fontSize: '14px'}}>No Return</span>
      </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center'}}>
      <CheckCircleFilled style={{ color: 'green', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'green', fontSize: '14px'}}>Return Done</span>
      </div>
          )
        }
    </>
    ) 
};

console.log('allDefectSheetsCopy', allDefectSheetsCopy);


  return (
    <div>
      <h2 style={{marginBottom: '10px', marginTop: 0}}>Vehicle Defect Report</h2>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "flex-end", display: "flex" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {/* <DatePicker
                value={deliveryDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setDeliveryDate(date);
                }}
                className={classes.dateField}
              /> */}
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
              }}
              placeholder="Select Date"
              />
              <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>

            </Box>
          </Grid>
        </Grid>

        {routes.length > 0 && (
          <div className={classes.selectStyle}>
          <Select
            mode="multiple"
            style={{
              width: "50%",
              margin: "2px",
              fontSize: '14px',
              marginTop: '15px'
            }}
            allowClear
            placeholder="Select Route"
            onChange={(value) => {
              setSelectedDriverRoute(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {routes.slice().sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
              <Option key={item.id} value={item.id}>
                {`${item.title} (${item.code}) - ${item.registration_number} - ${item.driver_name} `}
              </Option>
            ))}
          </Select>
        </div>
        )}

        

        <br />
        <>
        { allDefectSheetsCopy.length > 0 && routes?.length > 0 &&
        <>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
         <h2 >Vehicle Defect Report</h2>
        </div>
        {
            allDefectSheetsCopy.length > 0 && allDefectSheetsCopy.map((data, index) => (
                <>
                <Collapse style={{margin: '10px 0px'}} 
                // defaultActiveKey={defaultActiveKeys}
                 >
              <Panel header={
              <>
              <span>Route: </span>
              <span style={{ marginRight: '10px', fontWeight: 'bold'}}>{data.route}</span>
              <span>Registration: </span>
              <span style={{ marginRight: '10px', fontWeight: 'bold'}}>{data.registration_number}</span>
              <span>Driver: </span>
              <span style={{ marginRight: '10px', fontWeight: 'bold'}}>{data.driver}</span>
              
              </>
              } key={index} extra={genExtraContent(index, data)}>
              <div className={classes.dataSheet}>
      <>
      <Row gutter={[16, 16]}>
      {
                 data?.defect_sheets.filter(item => item.type == 1).length > 0 ? (
                  data?.defect_sheets.filter(item => item.type == 1).map((defectsItems)=> (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <h3>Checkout</h3>
      <div className={classes.antTable}>
                <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  // dataSource={defectsItems.defect.slice().sort((a, b) => {
                  //     const titleA = a.title.toUpperCase();
                  //     const titleB = b.title.toUpperCase();
                  //     if (titleA < titleB) {
                  //       return -1;
                  //     }
                  //     if (titleA > titleB) {
                  //       return 1;
                  //     }
                  //     return 0;
                  //   })}
                  dataSource={defectsItems.defect.slice().sort((a, b) => {
                    if (a.required && !b.required) {
                      return -1;
                    }
                    if (!a.required && b.required) {
                      return 1;
                    }
                  
                    const titleA = a.title.toUpperCase();
                    const titleB = b.title.toUpperCase();
                    
                    if (titleA < titleB) {
                      return -1;
                    }
                    if (titleA > titleB) {
                      return 1;
                    }
                  
                    return 0;
                  })}
                  
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />
                </div>
      </Col>
                  ))
                 ) : (
                  
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <h3>Checkout</h3>
        <span style={{ color: 'red', fontSize: '14px'}}>No Record Found</span>
      </Col>
                 ) 
                }
      
              {
                 data?.defect_sheets.filter(item => item.type == 2).length > 0 ? (
                  data?.defect_sheets.filter(item => item.type == 2).map((defectsItems)=> (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <h3>Return</h3>
      <div className={classes.antTable}>
                <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={
                    defectsItems.defect.slice().sort((a, b) => {
                      const titleA = a.title.toUpperCase();
                      const titleB = b.title.toUpperCase();
                      if (titleA < titleB) {
                        return -1;
                      }
                      if (titleA > titleB) {
                        return 1;
                      }
                      return 0;
                    })}
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />
                </div>
              </Col>
                  ))
                 ) : (
                  
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <h3>Return</h3>
        <span style={{ color: 'red', fontSize: '14px'}}>No Record Found</span>
      </Col>
        ) 
      }
      
      </Row>
      </>
    </div>
        </Panel>
          </Collapse>
          <Divider style={{ height: "2px", background: "#006cb8" }} />
          </>
          ))
        }
        </>
      }
        </>
        <Modal
          open={filesOpen}
          onCancel={handleFilesClose}
          okButtonProps={{ style: {display: 'none'}}}
          title="Defect Details"
          cancelText="Close"
          width={900}
        >
          <>
             <Descriptions style={{ marginBottom: '5px'}} bordered size={'small'}>
             <Descriptions.Item label={`Title`} labelStyle={{ width: '200px'}}><span style={{ fontWeight: 'bold'}}>{selectedRecord?.title?.replace(/_/g, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span> 
             </Descriptions.Item>
              </Descriptions>
              <Descriptions style={{ marginBottom: '5px'}} bordered size={'small'} >
              <Descriptions.Item label={`Status`} labelStyle={{ width: '200px'}}> {getStatusText(selectedRecord?.status)} 
              </Descriptions.Item>
              </Descriptions>
              <Descriptions style={{ marginBottom: '5px'}} bordered size={'small'} >
              <Descriptions.Item label={`Road Worthy`} labelStyle={{ width: '200px'}}>{getWorthyStatusText(selectedRecord?.notWorthy)} 
              </Descriptions.Item>
              </Descriptions>
              <Descriptions style={{ marginBottom: '5px'}} bordered size={'small'} >
              <Descriptions.Item label={`Notes`} labelStyle={{ width: '200px'}}>{selectedRecord?.notes || ''}
              </Descriptions.Item>
              </Descriptions>
              
         {selectedRecord?.images?.length > 0 && <h4>Files:</h4>}
        <Image.PreviewGroup>
          {selectedRecord?.images?.length > 0 &&
            selectedRecord?.images?.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/defect_sheets/${selectedRecord?.route_status_id}/${item}`;
                return (
                  <div
                    style={{
                      position: "relative",
                      marginRight: "10px",
                      display: "inline-block",
                    }}
                  >
                    <Image
                      width={200}
                      height={150}
                      style={{ padding: "5px", border: "1px solid lightgrey" }}
                      src={imageUrl}
                    />
                  </div>
                );
              })}
        </Image.PreviewGroup>
        </>
      </Modal>
        <Message type={type} msg={loadMessage} />
    </div>
  );
}
