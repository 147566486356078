import React, { useEffect, useState } from "react";
import "./reminder.css";
import { Button, DatePicker, message, Popconfirm, Table, Tag, Tooltip } from "antd";
import {
  CheckCircleFilled,
    CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import AuthService from "../../Components/auth/AuthService";
import { makeStyles } from "@mui/styles";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import AddServiceTaskModal from "../../Components/Modals/AddServiceTaskModal";
import EditServiceTaskModal from "../../Components/Modals/EditServiceTaskModal";
import { Autocomplete, Box, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { vehicleDueOptions, vehicleServiceTasks } from "../../Components/common/constants";
import dayjs from "dayjs";
import Message from "../../Components/common/Message";
import CompleteReminderModal from "../../Components/Modals/CompleteReminderModal";
import TaskHistoryModal from "../../Components/Modals/TaskHistoryModal";
import ContactHistoryModal from "../../Components/Modals/ContactHistoryModal";
import EditContactModal from "../../Components/Modals/EditContactModal";
import ResolveContactModal from "../../Components/Modals/ResolveContactModal ";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));


const ContactReminder = () => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [allReminders, setAllReminders] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const [selectedDueStatus, setSelectedDueStatus] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");

  const [historyRecord, setHistoryRecord] = React.useState([]);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);


  const getStatusInfo = (date) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference <= 30) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <HistoryOutlined />,
      };
    }
  };


  const remainingReminderColumns = [
    
    {
      title: 'Next Due',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      }

    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date }) => {
        const { color, text, icon } = getStatusInfo(moment(due_date, "YYYY-MM-DD"));
        return (
          <Tag color={color} icon={icon} style={{ marginLeft: '4px' }}>
            {text}
          </Tag>
        );
      },
    },

    {
      align: "center",
      title: "Last Completed",
      render: (record) => {
        return (
          <>
          <span>{record.last_completed ? moment(record.last_completed, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      dataIndex: "",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Resolve">
            <CheckCircleFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "green",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsCompleteModalOpen(true)
              }}
            />
            </Tooltip>
            {
              record?.last_completed && (
                <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
              )
            }
            
            <Tooltip title="Edit">
            <EditFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "#006cb8",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsEditModalOpen(true)
              }}
            />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getHistory = async (data) => {
    const DataToSend = {
      vehicle_id: data?.vehicle_id,
      type: data?.type
    }
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/reminder/vehicle-reminders-history", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })

      if(res?.ack) {
      console.log('res', res);
      if(res?.reminders.length > 0) {
        setHistoryRecord(res?.reminders)
        setIsHistoryModalOpen(true)
      } else  {
        message?.info('No related history found')
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }


  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllVehicle(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };


  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedVehicle) search.vehicle_id = selectedVehicle.id;
    if (selectedDueStatus) search.status = selectedDueStatus.id;
    if (page && pageChange) search.page = page;

    showLoading(layoutDispatch);
    Auth.fetch("/reminder/contact-reminders", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res?.ack) {
        console.log('res', res);
        setAllReminders(res?.reminders)
        setTotalPages(Math.ceil(res?.total_count / limit));
      } else {
        setAllReminders([])
        message.error(res?.message)
      }
    });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    startDate,
    endDate,
    selectedVehicle,
    selectedDueStatus,
    page
  ]);

  useEffect(()=> {
    getAllVehicles()
  },[])

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <div>
      <div className="page-header">
        <h2>Contact Renewal Reminders</h2>
      </div>

      <div>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">Start Date:</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">End Date:</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="route">Vehicles</label>
          <Autocomplete
            id="vehicles"
            size="small"
            options={allVehicle}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={selectedVehicle}
            onChange={(e, values) => {
              setSelectedVehicle(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Vehicle"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            id="dueStatus"
            size="small"
            options={vehicleDueOptions}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedDueStatus}
            onChange={(e, values) => {
              setSelectedDueStatus(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.antTable}>
        <Table
          columns={remainingReminderColumns}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          dataSource={allReminders}
          footer={() => `List of Contact Reminders - Total Records: ${allReminders?.length === 0 ? "0" : allReminders?.length}`}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>

      <Message type={type} msg={loadMessage} />
      <ContactHistoryModal isModalOpen={isHistoryModalOpen} setIsModalOpen={setIsHistoryModalOpen} history={historyRecord} setHistory={setHistoryRecord} />
      <EditContactModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} getData={search} />
      <ResolveContactModal isModalOpen={isCompleteModalOpen} setIsModalOpen={setIsCompleteModalOpen} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} getData={search} />
      
    </div>
  );
};

export default ContactReminder;
