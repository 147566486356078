import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Message from "../../common/Message";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import { FormButton } from "../../UiElements/UiElements";
import DatePicker from "react-date-picker";
import { truckSide } from "../../common/constants";
import moment from "moment";
import AuthService from "../../auth/AuthService";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../../context/loadingContext";
import config from "./../../../Components/config";
import PreviewModal from "../maintenance/PreviewModal";
import CompareIcon from "@mui/icons-material/Compare";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  Fade,
} from "@mui/material";
import { DatePickerField } from "../../../Components/InputFields/InputFields";
const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    zIndex: "5000",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
  dateField: {
    marginRight: "10px",
  },
  compareImages: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },
  VisibilityIconClass: {
    position: "absolute",
    // boxShadow: "1px 1px 1px 1px #8c9497",
    top: "-5px",
    zIndex: "555555",
    fontSize: "30px",
    cursor: "pointer",
    color: "#060606",
    right: "-25px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompareVehicleImage({
  handleClose,
  open,
  VehicleRestoration,
  vehicleId,
}) {
  var layoutDispatch = useLoadingDispatch();
  const Auth = new AuthService();
  const [side, setSide] = React.useState("f");
  const [date1, setDate1] = React.useState(new Date());
  const [date2, setDate2] = React.useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );

  const [uploadedImagesDates, setUploadedImagesDates] = React.useState({});
  const [date1Data, setDate1Data] = React.useState([]);
  const [date2Data, setDate2Data] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openPreviewModal, setOpenPrevModal] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState("");
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const [previewImageData, setPreviewImageData] = useState(null);


  // ==============for preview and compare images from both sides======
  const [previewData, setPreviewData] = useState({
    date1ImagePath: null,
    date2ImagePath: null,
  });
  const [selectedImage, setSelectedImage] = useState({
    date1Image: {
      imageName: null,
      imagePath: null,
      imageSide: null,
    },
    date2Image: {
      imageName: null,
      imagePath: null,
      imageSide: null,
    },
  });
  let imageBorder = "4px solid green";
  const classes = useStyles({ screenWidth });

  const handleSinglePreviewModal = (path) => {
    setPreviewImageData(path);
    setOpenPreviewImage(true);
    showLoading(layoutDispatch);
  };

  const handleOpenPreviewModal = () => {
    let { date1Image, date2Image } = selectedImage;
    if (date1Image.imageName === null) {
      setType("error");
      setLoadMessage("please select any image of Date1");
    } else if (date2Image.imageName === null) {
      setType("error");
      setLoadMessage("please select any image of Date2");
    } else {
      setOpenPrevModal(true);
      setLoadMessage("");
      setPreviewData({
        date1ImagePath: date1Image,
        date2ImagePath: date2Image,
      });
    }
  };

  useEffect(() => {
    Auth.fetch(
      `/maintenance/maintenance-uploaded-images?vehicle_id=${vehicleId}`
    )
      .then((res) => {
        if (res.ack) {
          let uploadedImagesDates1 = res.uploaded_images_dates;
          let uploadedImages = [];
          let dates = [];
          dates.completed = [];
          uploadedImagesDates1.map((cr) => {
            dates.completed.push(moment(cr.maintenance_date).toDate());
          });
          uploadedImages[0] = dates;
          setUploadedImagesDates(uploadedImages);
        } else {
          let temp = {};
          temp.completed = [];
          temp.completed.push(new Date());
          setUploadedImagesDates(temp);
          // setUploadedImagesDates([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSearch = () => {
    showLoading(layoutDispatch);
    if (!date1 || !date2 || !moment(date1).isValid() || !moment(date2).isValid()) {
      hideLoading(layoutDispatch);
      let rand = Math.random() * 50;
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
      setLoadMessage("Please provide valid dates." + str);
      setType('error')
      return;
    }
    let reqBody = {
      date1: moment(date1).format("YYYY-MM-DD"),
      date2: moment(date2).format("YYYY-MM-DD"),
      vehicle_id: vehicleId,
    };

    let rand = Math.random() * 50; // for unique message each time
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    Auth.fetch("/maintenance/maintenance-compare", {
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        hideLoading(layoutDispatch);

        if (res.ack === true) {
          if (res.maintenances.length > 0) {
            let date1Data1 = [];
            let date2Data1 = [];

            res.maintenances.map((m) => {
              m.front_files1 = JSON.parse(m.front_files);
              m.right_files1 = JSON.parse(m.right_files);
              m.left_files1 = JSON.parse(m.left_files);
              m.tail_files1 = JSON.parse(m.tail_files);

              if (m.created_date == moment(date1).format("YYYY-MM-DD")) {
                date1Data1.push(m);
              } else if (m.created_date == moment(date2).format("YYYY-MM-DD")) {
                date2Data1.push(m);
              }
            });

            console.log(date1Data1);
            setDate1Data(date1Data1);
            setDate2Data(date2Data1);
          }

          setType("success");
          setLoadMessage(res.message + str);

          setLoading(false);
        } else {
          setType("error");
          setLoadMessage(res.message + str);
          setLoading(false);
        }
      })
      .catch((err) => {
        hideLoading(layoutDispatch);
        console.log(err);
      });
  };

  //=================Date1 function=============
  const handleSeleectDate1Image = (imageSide, imageName, imagePath) => {
    if (imageName === selectedImage.date1Image.imageName) {
      setSelectedImage((state) => ({
        ...state,
        date1Image: {
          imageName: null,
          imagePath: null,
          imageSide: null,
        },
      }));
    } else {
      setSelectedImage((state) => ({
        ...state,
        date1Image: {
          imageName,
          imagePath,
          imageSide,
        },
      }));
    }
  };

  //=================Date2 function=============
  const handleSeleectDate2Image = (imageSide, imageName, imagePath) => {
    if (imageName === selectedImage.date2Image.imageName) {
      setSelectedImage((state) => ({
        ...state,
        date2Image: {
          imageName: null,
          imagePath: null,
          imageSide: null,
        },
      }));
    } else {
      setSelectedImage((state) => ({
        ...state,
        date2Image: {
          imageName,
          imagePath,
          imageSide,
        },
      }));
    }
  };

  const { date1Image, date2Image } = selectedImage;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {
            <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
              Compare Vehicle Images
            </Typography>
          }
          <Typography
            sx={{ ml: 2, flex: screenWidth > 650 ? 2 : 1 }}
            variant="h6"
            component="div"
          >
            {VehicleRestoration}
          </Typography>
          {screenWidth > 650 && (
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <List>
        <Grid container spacing={2} sx={{ paddingX: "20px", paddingY: "20px" }}>
          <Grid
            item
            xs={11}
            sm={6}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <FormControl
              sx={{
                width: screenWidth < 600 ? "100%" : "180px",
              }}
              size="small"
            >
              <InputLabel id="side-label"></InputLabel>
              <Select
                labelId="side-label"
                id="side"
                value={side}
                onChange={(e) => {
                  setSide(e.target.value);
                }}
              >
                {/* <MenuItem value={""}>--Select Status--</MenuItem> */}
                {truckSide.map((s) => {
                  return <MenuItem value={s.id}>{s.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={11} sm={6} sx={{ textAlign: "left" }}>
            <FormButton
              sx={{ marginLeft: "10px" }}
              size="small"
              type="search"
              onClick={handleSearch}
            >
              Search
            </FormButton>
          </Grid>
        </Grid>
        <Divider />
        <Box
          sx={{
            position: "relative",
          }}
        >
          {date1Image.imageName !== null && date2Image.imageName !== null && (
            <Tooltip
              title="compare images"
              onClick={handleOpenPreviewModal}
              sx={{
                cursor: "pointer",
              }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  cursor: "pointer",
                  position: "fixed",
                  bgcolor: "#308BE6",
                  top: "60%",
                  zIndex: "50000",
                  width: "20px",
                  left: "calc(50% - 38px)",
                }}
                onClick={handleOpenPreviewModal}
              >
                <CompareIcon />
              </Button>
            </Tooltip>
          )}

          <Box
            sx={{
              width: "100%",
              minHeight: "85vh",
              display: "flex",
              overflowY: "auto",
              flexWrap: "wrap",
              paddingX: "5px",
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid #d1caca",
                width: "50%",
                height: "inherit",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    paddingY: "20px",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    {/* <DatePicker
                      label={"Noor"}
                      value={date1}
                      format={"dd-MM-yyyy"}
                      onChange={(date) => {
                        setDate1(date);
                      }}
                      id="Date1"
                      className={classes.dateField}
                    /> */}
                    <DatePickerField
                      todayButton="Today"
                      format="yyyy-MM-dd"
                      selected={date1}
                      onChange={(date) => setDate1(date)}
                      style={{
                        textAlign: "center",
                      }}
                      isCenter={screenWidth > 350 ? true : false}
                      highlightedDatesList={uploadedImagesDates}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {/* Display Date 1 Data */}
                  {date1Data.map((d1) => {
                    if (side == "f") {
                      return (
                        d1.front_files1 &&
                        d1.front_files1.length > 0 &&
                        d1.front_files1.map((f) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height:
                                    screenWidth > 768
                                      ? "250px"
                                      : screenWidth > 400 && screenWidth < 767
                                      ? "180px"
                                      : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date1Image?.imageName === f &&
                                      date1Image.imageSide === "f" &&
                                      imageBorder,
                                    opacity:
                                      date1Image?.imageName === f &&
                                      date1Image.imageSide === "f" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${f}`}
                                  className={classes.compareImages}
                                  onClick={() =>
                                    handleSeleectDate1Image(
                                      "f",
                                      f,
                                      `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${f}`
                                    )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${f}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "r") {
                      return (
                        d1.right_files1 &&
                        d1.right_files1.length > 0 &&
                        d1.right_files1.map((r) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date1Image?.imageName === r &&
                                      date1Image.imageSide === "r" &&
                                      imageBorder,
                                    opacity:
                                      date1Image?.imageName === r &&
                                      date1Image.imageSide === "r" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${r}`}
                                  className={classes.compareImages}
                                  onClick={() =>
                                    handleSeleectDate1Image(
                                      "r",
                                      r,
                                      `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${r}`
                                    )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${r}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "l") {
                      return (
                        d1.left_files1 &&
                        d1.left_files1.length > 0 &&
                        d1.left_files1.map((l) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date1Image?.imageName === l &&
                                      date1Image.imageSide === "l" &&
                                      imageBorder,

                                    opacity:
                                      date1Image?.imageName === l &&
                                      date1Image.imageSide === "l" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${l}`}
                                  className={classes.compareImages}
                                  onClick={
                                    () =>
                                      handleSeleectDate1Image(
                                        "l",
                                        l,
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${l}`
                                      )
                                    // handleOpenPreviewModal(
                                    //   `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${l}`
                                    // )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${l}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "t") {
                      return (
                        d1.tail_files1 &&
                        d1.tail_files1.length > 0 &&
                        d1.tail_files1.map((t) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date1Image?.imageName === t &&
                                      date1Image.imageSide === "t" &&
                                      imageBorder,

                                    opacity:
                                      date1Image?.imageName === t &&
                                      date1Image.imageSide === "t" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${t}`}
                                  className={classes.compareImages}
                                  s
                                  onClick={
                                    () =>
                                      handleSeleectDate1Image(
                                        "t",
                                        t,
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${t}`
                                      )
                                    // handleOpenPreviewModal(
                                    //   `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${t}`
                                    // )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d1.id}/${t}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    }
                  })}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "50%",
                height: "inherit",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    paddingY: "20px",
                    textAlign: screenWidth < 778 ? "right" : "center",
                  }}
                >
                  <Box sx={{ paddingLeft: "2px" }}>
                    <DatePickerField
                      todayButton="Today"
                      format="yyyy-MM-dd"
                      selected={date2}
                      onChange={(date) => setDate2(date)}
                      isCenter={screenWidth > 350 ? true : false}
                      highlightedDatesList={uploadedImagesDates}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  {/* Display Date 2 Data */}
                  {date2Data.map((d2) => {
                    if (side == "f") {
                      return (
                        d2.front_files1 &&
                        d2.front_files1.length > 0 &&
                        d2.front_files1.map((f) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height:
                                    screenWidth > 768
                                      ? "250px"
                                      : screenWidth > 400 && screenWidth < 767
                                      ? "150px"
                                      : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date2Image?.imageName === f &&
                                      date2Image.imageSide === "f" &&
                                      imageBorder,
                                    opacity:
                                      date2Image?.imageName === f &&
                                      date2Image.imageSide === "f" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${f}`}
                                  className={classes.compareImages}
                                  onClick={() =>
                                    handleSeleectDate2Image(
                                      "f",
                                      f,
                                      `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${f}`
                                    )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${f}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "r") {
                      return (
                        d2.right_files1 &&
                        d2.right_files1.length > 0 &&
                        d2.right_files1.map((r) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date2Image?.imageName === r &&
                                      date2Image.imageSide === "r" &&
                                      imageBorder,

                                    opacity:
                                      date2Image?.imageName === r &&
                                      date2Image.imageSide === "r" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${r}`}
                                  className={classes.compareImages}
                                  onClick={() =>
                                    handleSeleectDate2Image(
                                      "r",
                                      r,
                                      `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${r}`
                                    )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${r}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "l") {
                      return (
                        d2.left_files1 &&
                        d2.left_files1.length > 0 &&
                        d2.left_files1.map((l) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                <img
                                  style={{
                                    border:
                                      date2Image?.imageName === l &&
                                      date2Image.imageSide === "l" &&
                                      imageBorder,
                                    opacity:
                                      date2Image?.imageName === l &&
                                      date2Image.imageSide === "l" &&
                                      ".6",
                                  }}
                                  src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${l}`}
                                  className={classes.compareImages}
                                  onClick={() =>
                                    handleSeleectDate2Image(
                                      "l",
                                      l,
                                      `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${l}`
                                    )
                                  }
                                />
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${l}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    } else if (side == "t") {
                      return (
                        d2.tail_files1 &&
                        d2.tail_files1.length > 0 &&
                        d2.tail_files1.map((t) => {
                          return (
                            <ul
                              style={{
                                paddingTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent:
                                  screenWidth < 778 ? "left" : "center",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: screenWidth > 768 ? "250px" : "80%",
                                  height: screenWidth > 768 ? "250px" : "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                {t.match(/\.(jpg|jpeg|png|gif)$/i) && (
                                  <img
                                    alt="image"
                                    style={{
                                      border:
                                        date2Image?.imageName === t &&
                                        date2Image.imageSide === "t" &&
                                        imageBorder,
                                      opacity:
                                        date2Image?.imageName === t &&
                                        date2Image.imageSide === "t" &&
                                        ".6",
                                    }}
                                    src={`${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${t}`}
                                    className={classes.compareImages}
                                    onClick={
                                      () =>
                                        handleSeleectDate2Image(
                                          "t",
                                          t,
                                          `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${t}`
                                        )
                                      // handleOpenPreviewModal(
                                      //   `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${t}`
                                      // )
                                    }
                                  />
                                )}
                                {
                                  // Add for video
                                }
                                <Tooltip title="preview">
                                  <VisibilityIcon
                                    className={classes.VisibilityIconClass}
                                    onClick={() =>
                                      handleSinglePreviewModal(
                                        `${config.tms_uploads_cdn}/${config.env}/vehicles/${d2.id}/${t}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </ul>
                          );
                        })
                      );
                    }
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Message type={type} msg={loadMessage} />
        {openPreviewModal && (
          <PreviewModal
            open={openPreviewModal}
            side={side}
            
            handleClose={() => {
              setOpenPrevModal(false);
              setPreviewData({
                date1ImagePath: null,
                date2ImagePath: null,
              });
            }}
            imageData={previewData}
            VehicleRestoration={VehicleRestoration}
          />
        )}

        <Modal
          className={classes.modal}
          open={openPreviewImage}
          onClose={() => {
            setOpenPreviewImage(false);
          }}
        >
          <Fade in={openPreviewImage} timeout={500} className={classes.img}>
            {previewImageData !== null && (
              <img
                src={previewImageData}
                onLoad={() => {
                  hideLoading(layoutDispatch);
                }}
                alt="asd"
                style={{
                  maxHeight: "90%",
                  maxWidth: "90%",
                  borderRadius: "10px",
                }}
              />
            )}
          </Fade>
        </Modal>
      </List>
    </Dialog>
  );
}
